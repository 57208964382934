import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';
import { userInfo } from 'os';

@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    public user;
    public menu = MENU;

    constructor(public appService: AppService) {}

    ngOnInit() {
        this.user = this.appService.user;   
    }
}

export const MENU = [
    { name: 'Operaciones',path: ['/'], icon: 'fa-tachometer-alt',perfil: '15kwqgk3123'},
    { name: 'Empresas',path: ['/empresas'], icon: 'fa-city', perfil: '15kwqgk3123'},

    { name: 'Operaciones',path: ['/'], icon: 'fa-tachometer-alt',perfil: '2fk6i2o3143'},    
    { name: 'Solicitudes',path: ['/solicitudes'], icon: 'fa-calendar-check', perfil: '2fk6i2o3143'},
    { name: 'Reservas',path: ['/reservas'], icon: 'fa-money-check', perfil: '2fk6i2o3143'},
    { name: 'Visas',path: ['/visas'], icon: 'fa-passport',  perfil: '2fk6i2o3143'},
    { name: 'Proveedores',path: ['/proveedores'], icon: 'fa-boxes',  perfil: '2fk6i2o3143'},
    { name: 'IATA',path: ['/codigo_iata'], icon: 'fa-code',  perfil: '2fk6i2o3143'},
    { name: 'Clientes',path: ['/clientes'], icon: 'fa-user-friends',  perfil: '2fk6i2o3143'},
    { name: 'Politicas',path: ['/politicas'], icon: 'fa-gavel',  perfil: '2fk6i2o3143'},
    { name: 'Usuarios',path: ['/usuarios'], icon: 'fa-users',  perfil: '2fk6i2o3143'},

    { name: 'Operaciones',path: ['/'], icon: 'fa-tachometer-alt',perfil: '3af3s12f153'},    
    { name: 'Solicitudes',path: ['/solicitudes'], icon: 'fa-calendar-check', perfil: '3af3s12f153'},
    { name: 'Reservas',path: ['/reservas'], icon: 'fa-money-check', perfil: '3af3s12f153'},
    { name: 'Visas',path: ['/visas'], icon: 'fa-passport',  perfil: '3af3s12f153'},    
    { name: 'Proveedores',path: ['/proveedores'], icon: 'fa-boxes',  perfil: '3af3s12f153'},
    { name: 'IATA',path: ['/codigo_iata'], icon: 'fa-code',  perfil: '3af3s12f153'},
    { name: 'Clientes',path: ['/clientes'], icon: 'fa-user-friends',  perfil: '3af3s12f153'},
    // { name: 'Main Menu', icon: 'fa-list-ul',
    //     children: [
    //         {
    //             name: 'Sub Menu',
    //             path: ['/sub-menu-1']
    //         },

    //         {
    //             name: 'Blank',
    //             path: ['/sub-menu-2']
    //         }
    //     ]
    // }
];
