<div *ngIf="empresaValida" class="container col-md-8 bg-light">
  <form [formGroup]="nuevaSolicitudForm" (ngSubmit)="enviarSolicitud()">
  <div class="row mt-5">
    <div class="col-12 text-center mt-2 fontBlue"><h3>SOLICITAR COTIZACIÓN</h3></div>
    <div class="col-12 mt-4  text-center fontBlue">
      <div class="form-floating mb-3">
        <input formControlName="nombre" type="text" class="form-control" placeholder=">Nombre Completo">
        <label>Nombre Completo</label>
      </div>
    </div>
    <div class="col-6 mt-4  text-center fontBlue">
      <div class="form-floating mb-3">
        <input formControlName="celular" type="text" class="form-control" placeholder=">Celular">
        <label>Celular</label>
      </div>
    </div>
    <div class="col-6 mt-4  text-center fontBlue">
      <div class="form-floating mb-3">
        <input formControlName="email" type="email" class="form-control" placeholder=">Email">
        <label>Email</label>
      </div>
    </div>
    <div class="col-12 text-center mt-4 fontBlue">
      <label for="nombre" class="form-label text-center">¿A dónde Deseas Viajar?<br>
        Indicanos Fecha de viaje y el numero de personas adultos y niños</label>
    </div>

    <div class="col-sm-12 col-md-4 text-center mt-1 fontBlue">
      <div class="form-floating mb-1">
        <input formControlName="fecha" type="date" class="form-control" placeholder=">Fecha estimada">
        <label>Fecha estimada</label>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 text-center mt-1 fontBlue">
      <div class="form-floating mb-1">
        <input formControlName="adultos" type="text" class="form-control" placeholder=">Adultos">
        <label>Adultos</label>
      </div>
    </div> 
    <div class="col-sm-6 col-md-4 text-center mt-1 fontBlue">
      <div class="form-floating mb-1">
        <input formControlName="infantes" type="text" class="form-control" placeholder=">Niños">
        <label>Niños</label>
      </div>
    </div>        
    <div class="col-12 text-center mt-4 fontBlue">
      <textarea formControlName="detalle" rows="5" type="text" class="form-control" placeholder=""></textarea>
    </div>

    <div class="col-12 text-center mt-4 mb-4"><button class="btn btn-warning btn-lg">Enviar Solicitud</button></div>
  </div>  
  </form>
</div>

<div  class="container col-md-8 bg-light">
  <div class="row mt-5">
    <div *ngIf="isAuthLoading" class="col-12">
      <div class="d-flex justify-content-center">
        <div class="spinner-border fontBlue" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>

    <div *ngIf="empresaNoValida" class="col-12 text-center fontBlue  mt-5">
      <h1> Error 404</h1>
      <small>Pongase en contacto con el departamento de soporte.</small>
    </div>
  </div>
</div>