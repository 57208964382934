<section class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-12">
                <h1>
                  Visas
                  <button class="btn btn-sm btn-success " type="button" data-bs-toggle="modal" data-bs-target="#sendformato" aria-controls="sendformato" (click)="selectFormato = null"><i class="fa fa-paper-plane"></i> </button>
                  <button class="btn btn-sm btn-primary ml-1" type="button" data-bs-toggle="modal" data-bs-target="#verformato"><i class="fa fa-passport"></i> Formatos</button>
                  <button class="btn btn-sm btn-primary ml-1" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNuevaSolicitud" aria-controls="offcanvasNuevaSolicitud" (click)="selectFormato = null"><i class="fa fa-plus"></i> Nueva Solicitud</button>
                </h1>
              </div>

            </div>

          </div>
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">

                  <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                  <li class="breadcrumb-item active">Visas</li>
              </ol>
          </div>
      </div>
  </div>
  <!-- /.container-fluid -->
</section>

  <!-- Modal sendFormato -->
  <div class="modal fade" id="sendformato" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Enviar Formato Whatsapp: </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-4" *ngFor="let item of listFormato">
              <img class="img-fluid img-thumbnail" src="{{item.img_url}}" style="max-width: 80px;cursor: pointer;" (click)="selectFormatoSend(item)">
            </div>
            <div class="col-12 mt-3">
              <h4>{{selectFormato?.nombre | titlecase}}</h4>
            </div>
            <div class="col-8">
              <input type="number" class="form-control input-lg" [(ngModel)]="whatsappNumero" placeholder="Número whatsapp">
            </div>

            <div class="col-4">

              <button *ngIf="!whatsappNumero" class="btn btn-block btn-secondary text-center" disabled="disabled">
                <i class="fa fa-paper-plane"></i>
              </button>

              <a *ngIf="whatsappNumero" class="btn btn-block btn-success text-center" href="https://wa.me/57{{whatsappNumero}}?text=https://aplicacion.faroapp.com.co/reporte/visa/formato/{{selectFormato?.id}}" target="_blank">
                <i class="fa fa-paper-plane" style="color:white"></i>
              </a>
            </div>
          </div>
     
        </div>
      </div>
    </div>
  </div>

  <!-- Modal VerFormato -->
  <div class="modal fade" id="verformato" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Formatos Visas  <button type="button" class="btn btn-sm btn-outline-dark" data-bs-toggle="modal" data-bs-target="#crearformato"><i class="fa fa-file-text"></i> Crear formato visa</button></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body"> 
          <table class="table table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th></th>
                <th scope="col">ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of listFormato">
                <th scope="row"><img class="img-fluid img-thumbnail" src="{{item.img_url}}" style="max-width: 120px;"></th>
                <td>
                  <div><b style="font-size: 1.3em;">{{item.nombre | titlecase}}</b> </div>
                  <div>COSTO CONSULARES <b>{{item.costo}}</b> </div>
                  <div>COSTO SERVICIO <b>{{item.costo_servicio}}</b> </div>
                </td>
                <td><div class="dropdown">
                  <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton1"
                    data-bs-toggle="dropdown" aria-expanded="false"></button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><span class="dropdown-item" style="cursor: pointer;" (click)="ver(item)">Ver formato</span></li>
                    <li><span class="dropdown-item"><a href="reserva/{{item.id}}" target="_blank"
                          style="color: #000;">Enviar formato Whatsapp</a></span></li>
                  </ul>
                </div></td>
              </tr>
            </tbody>
          </table>      
        </div>
      </div>
    </div>
  </div>


  <!-- Modal crear Formato -->
  <div class="modal fade" id="crearformato" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Crear Formato Visa</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#verformato" aria-label="Close"></button>
        </div>
        <div class="modal-body"> 
          <form [formGroup]="nuevoForm" (ngSubmit)="nuevoFormato()">
            <div class="row">
 
              <div class="row">
                <div class="col-4">
                  <div class="text-center">
                    <div *ngIf="nuevoForm.value.img" class="user-image img-circle elevation-2 img-fluid" 
                        (click)="fileEditar.click()"
                        [ngStyle]="{'background-image':' url(' + nuevoForm.value.img_url || 'assets/img/bandera-default.png' + ')','height':'150px','width':'150px','background-size':'cover','background-position':'center','cursor':'pointer','margin-left':'auto','margin-right': 'auto'}">
                    </div>
  
                    <div *ngIf="!nuevoForm.value.img" class="user-image img-circle elevation-2 img-fluid" 
                        (click)="fileEditar.click()"
                        [ngStyle]="{'background-image':' url(' + 'assets/img/bandera-default.png' + ')','height':'150px','width':'150px','background-size':'cover','background-position':'center','cursor':'pointer','margin-left':'auto','margin-right': 'auto'}">
                    </div>
                    
                    <div *ngIf="loadingImg" class="spinner-border text-secondary" style="width: 3rem; height: 3rem;position: absolute;left: 48%;top: 35%;" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <input #fileEditar type="file" accept='image/*' (change)="preview(fileEditar.files,'editar')" style="display: none"/>                          
                  </div>  
                </div>
                <div class="col-8">
                  <div class="col-12">
                    <div class="form-floating mb-3">
                      <input formControlName="nombre" type="text" class="form-control" placeholder=">Nombre">
                      <label>Nombre</label>
                    </div>
                  </div>                   
                  <div class="col-6">
                    <div class="form-floating mb-3">
                      <input formControlName="costo" type="text" class="form-control" placeholder=">costo">
                      <label>Costos Consulares</label>
                    </div>
                  </div>              
                  <div class="col-6">
                    <div class="form-floating mb-3">
                      <input formControlName="costo_servicio" type="text" class="form-control" placeholder=">costo_servicio">
                      <label>Costo Servicio</label>
                    </div>
                  </div>  
                </div>
              </div>            
            
              <div class="col-12">
                <div class="form-floating mb-3">
                  <textarea class="form-control" formControlName="requisitos" placeholder="requisitos"
                  style="height: 190px"></textarea>
                  <label>Requisitos</label>
                </div>
              </div>             
              <div class="col-12">
                  <button class="btn btn-lg btn-block btn-primary" type="submit">Guardar</button>
              </div> 

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>


  <!-- Modal ver / editar Formato -->
  <div class="modal fade" id="editarformato" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Editar Formato Visa</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body"> 
          <form [formGroup]="editForm" (ngSubmit)="editFormato()">
            <div class="row">
              <div class="row">
                <div class="col-4">
                  <div class="text-center">
                    <div *ngIf="editForm.value.img" class="user-image img-thumbnail elevation-2 img-fluid" 
                        (click)="fileEditar.click()"
                        [ngStyle]="{'background-image':' url(' + editForm.value.img_url || 'assets/img/bandera-default.png' + ')','height':'150px','width':'150px','background-size':'cover','background-position':'center','cursor':'pointer','margin-left':'auto','margin-right': 'auto'}">
                    </div>
  
                    <div *ngIf="!editForm.value.img" class="user-image img-thumbnail elevation-2 img-fluid" 
                        (click)="fileEditar.click()"
                        [ngStyle]="{'background-image':' url(' + 'assets/img/bandera-default.png' + ')','height':'150px','width':'150px','background-size':'cover','background-position':'center','cursor':'pointer','margin-left':'auto','margin-right': 'auto'}">
                    </div>
                    
                    <div *ngIf="loadingImg" class="spinner-border text-secondary" style="width: 3rem; height: 3rem;position: absolute;left: 48%;top: 35%;" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <input #fileEditar type="file" accept='image/*' (change)="preview(fileEditar.files,'editar')" style="display: none"/>                          
                  </div>  
                </div>
                <div class="col-8">
                  <div class="col-12">
                    <div class="form-floating mb-3">
                      <input formControlName="nombre" type="text" class="form-control" placeholder=">Nombre">
                      <label>Nombre</label>
                    </div>
                  </div>                   
                  <div class="col-6">
                    <div class="form-floating mb-3">
                      <input formControlName="costo" type="text" class="form-control" placeholder=">costo">
                      <label>Costos Consulares</label>
                    </div>
                  </div>              
                  <div class="col-6">
                    <div class="form-floating mb-3">
                      <input formControlName="costo_servicio" type="text" class="form-control" placeholder=">costo_servicio">
                      <label>Costo Servicio</label>
                    </div>
                  </div>  
                </div>
              </div>            
            
              <div class="col-12">
                <div class="form-floating mb-3">
                  <textarea class="form-control" formControlName="requisitos" placeholder="requisitos"
                  style="height: 190px"></textarea>
                  <label>Requisitos</label>
                </div>
              </div>             
              <div class="col-12">
                  <button class="btn btn-lg btn-block btn-primary" type="submit">Actualizar</button>
              </div> 

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>






  <!-- Modal Nueva  -->
  <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNuevaSolicitud" aria-labelledby="offcanvasNuevaSolicitudLabel">
    <div class="offcanvas-header">
      <h5 id="offcanvasRightLabel"><i class="fa fa-passport"> </i> Nueva Solicitud</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <div class="row">
        <div class="col-12">
          <h5>Seleccione cliente <br></h5>
        </div>

        <div class="col-12">
          <div class="form-group">
            <!-- <label class="form-label">Buscar Cliente</label> -->
            <input [(ngModel)]="buscador" type="text" class="form-control" placeholder="Buscar Cliente">
          </div>

          <div *ngIf="!buscador"></div>

          <ul class="list-group mb-2" *ngIf="buscador">
            <button *ngFor="let item of listClientes | filtroCliente:buscador" type="button"
              class="list-group-item list-group-item-action" (click)="selectCliente(item)">
              <div class="mb-2 fw-bold">{{ item.nombre | titlecase }}</div>
              <div class="d-flex w-100 ">
                <small><i class="fa fa-envelope"></i> {{item.email}}</small>
              </div>
              <div class="d-flex w-100 ">
                <small><i class="fa fa-mobile-alt"></i> {{item.celular}}</small>
              </div>
            </button>

            <!-- <button class="list-group-item list-group-item-action text-bold mb-2 mt-2"
              style="background:#0d6efd; color:white;font-size: 1.0em" (click)="createCliente(buscador)">
              <i class="fa fa-user-plus"> </i> CREA UN CLIENTE.
            </button> -->
          </ul>

          <div *ngIf="clienteSeleccionado?.id" class="p-5 bg-primary text-center">
            <h4>{{clienteSeleccionado.nombre | titlecase}}</h4>
          </div>
        </div>

        <!-- <form [formGroup]="nuevoClienteForm" (ngSubmit)="newCliente()">
          <div class="col-12 mt-3" id="crearCliente" style="display: none;">
            <div class="card">
              <div class="card-body">
                <h5>Cliente</h5>
                <div class="form-group">
                  <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control" />
                </div>

                <div class="form-group">
                  <input formControlName="cc" placeholder="Nit. - C.C" type="text" class="form-control" />
                </div>

                <div class="form-group">
                  <input formControlName="email" placeholder="Email" type="text" class="form-control" />
                </div>

                <div class="form-group">
                  <input formControlName="direccion" placeholder="Direccion" type="text" class="form-control" />
                </div>

                <div class="form-group">
                  <input formControlName="celular" placeholder="Celular" type="text" class="form-control" />
                </div>
                <button type='submit'> Crear Cliente </button>
              </div>
            </div>
          </div>
        </form> -->
        <!-- <div class="col-12">
          <button type="button" class="btn btn-secondary btn-block" data-bs-dismiss="modal" data-bs-toggle="modal"
            data-bs-target="#nuevo"><i class="fa fa-chevron-left"></i> Atrás</button>
        </div>         -->



        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h5>Seleccione tipo visa</h5>
              <div class="row">
                <div class="col-4" *ngFor="let item of listFormato">
                  <img class="img-fluid img-thumbnail" src="{{item.img_url}}" style="max-width: 80px;cursor: pointer;" (click)="selectFormatoSend(item)">
                </div> 
              </div>
            </div>
          </div>
          <div class="card" *ngIf="selectFormato?.id">
            <div class="card-body">
              <div>Formato seleccionado</div>
              <div class="row">
                <div class="col-3">
                  <img class="img-fluid img-thumbnail" src="{{selectFormato.img_url}}" style="max-width: 80px;" >
                </div>
                <div class="col-9">
                  <h4>{{selectFormato?.nombre | titlecase}}</h4>
                </div>
              </div>              
            </div>
          </div>
        </div>
      </div>
      <button type="button" [disabled]="!clienteSeleccionado?.id || !selectFormato?.id" class="btn btn-block mb-2 mt-2" style="background:#0d6efd; color:white;font-size: 1.0em" (click)="crearSolicitud()">
        <i class="fa fa-passport"> </i> CREA SOLICITUD VISA.
      </button>

    </div>
  </div>


<section>
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#interesados" type="button" role="tab" aria-controls="interesados" aria-selected="true">Interesados</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#proceso" type="button" role="tab" aria-controls="proceso" aria-selected="false">En Proceso</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#agendado" type="button" role="tab" aria-controls="agendado" aria-selected="false">Agendado</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#finalizado" type="button" role="tab" aria-controls="finalizado" aria-selected="false">Finalizado</button>
    </li>    
  </ul>

  <div class="tab-content" id="myTabContent">
    <!-- TAB interesados -->
    <div class="tab-pane fade show active  bg-body" id="interesados" role="tabpanel" aria-labelledby="recientes-tab">
      <div class="container bg-body">
        <table class="table table-hover table-sm">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Visa</th>
              <th scope="col">Nombre</th>
              <th scope="col">Contacto</th>
              <th scope="col">Visto</th>
              <th scope="col">Estado</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr *ngFor="let item of listHistorial">
              <th scope="row">{{item.consecutivo}}</th>
              <td>{{item.solicitud_at}}</td>
              <td>{{item.nombre | titlecase}}</td>
              <td><span *ngIf="!item.usuario_id">WEB</span> <span *ngIf="item.usuario_id">{{item.usuario_nombre}}</span></td>
              <td>{{item.solicitud_up}}</td>
              <td>
                <span *ngIf="item.estado == -1" class="badge bg-danger">Cancelada</span>
                <span *ngIf="item.estado == 3" class="badge bg-success">Procesada</span>
                <span *ngIf="item.estado == 2" class="badge bg-warning">Pendiente</span>
                <span *ngIf="item.estado == 1" class="badge bg-warning">Pendiente</span>
              </td>
            </tr> -->
          </tbody>
        </table>
      </div>        
    </div>


    <!-- TAB proceso -->
    <div class="tab-pane fade" id="proceso" role="tabpanel" aria-labelledby="proceso-tab">
      <div class="container bg-body">
        <table class="table table-hover table-sm">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Visa</th>
              <th scope="col">Nombre</th>
              <th scope="col">Contacto</th>
              <th scope="col">Personas</th>
              <th scope="col">Fotos</th>
              <th scope="col">Formulario</th>
              <th scope="col">Pasaporte</th>
              <th scope="col">Fecha Cita</th>
              <th scope="col">Visto</th>
              <th scope="col">Estado</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr *ngFor="let item of listHistorial">
              <th scope="row">{{item.consecutivo}}</th>
              <td>{{item.solicitud_at}}</td>
              <td>{{item.nombre | titlecase}}</td>
              <td><span *ngIf="!item.usuario_id">WEB</span> <span *ngIf="item.usuario_id">{{item.usuario_nombre}}</span></td>
              <td>{{item.solicitud_up}}</td>
              <td>
                <span *ngIf="item.estado == -1" class="badge bg-danger">Cancelada</span>
                <span *ngIf="item.estado == 3" class="badge bg-success">Procesada</span>
                <span *ngIf="item.estado == 2" class="badge bg-warning">Pendiente</span>
                <span *ngIf="item.estado == 1" class="badge bg-warning">Pendiente</span>
              </td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </div>

    <!-- TAB agendado -->
    <div class="tab-pane fade  bg-body" id="agendado" role="tabpanel" aria-labelledby="agendado-tab">
      <div class="container bg-body">
        <table class="table table-hover table-sm">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Visa</th>
              <th scope="col">Nombre</th>
              <th scope="col">Contacto</th>
              <th scope="col">Personas</th>
              <th scope="col">Fecha Cita 1</th>
              <th scope="col">Fecha Cita 2</th>
              <th scope="col">Visto</th>
              <th scope="col">Estado</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr *ngFor="let item of listHistorial">
              <th scope="row">{{item.consecutivo}}</th>
              <td>{{item.solicitud_at}}</td>
              <td>{{item.nombre | titlecase}}</td>
              <td><span *ngIf="!item.usuario_id">WEB</span> <span *ngIf="item.usuario_id">{{item.usuario_nombre}}</span></td>
              <td>{{item.solicitud_up}}</td>
              <td>
                <span *ngIf="item.estado == -1" class="badge bg-danger">Cancelada</span>
                <span *ngIf="item.estado == 3" class="badge bg-success">Procesada</span>
                <span *ngIf="item.estado == 2" class="badge bg-warning">Pendiente</span>
                <span *ngIf="item.estado == 1" class="badge bg-warning">Pendiente</span>
              </td>
            </tr> -->
          </tbody>
        </table>
      </div>     
    </div>

    <!-- TAB finalizado -->
    <div class="tab-pane fade  bg-body" id="finalizado" role="tabpanel" aria-labelledby="finalizado-tab">
      <div class="container bg-body">
        <table class="table table-hover table-sm">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Visa</th>
              <th scope="col">Nombre</th>
              <th scope="col">Contacto</th>
              <th scope="col">Fecha Cita 1</th>
              <th scope="col">Fecha Cita 2</th>
              <th scope="col">Visto</th>
              <th scope="col">Estado</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr *ngFor="let item of listHistorial">
              <th scope="row">{{item.consecutivo}}</th>
              <td>{{item.solicitud_at}}</td>
              <td>{{item.nombre | titlecase}}</td>
              <td><span *ngIf="!item.usuario_id">WEB</span> <span *ngIf="item.usuario_id">{{item.usuario_nombre}}</span></td>
              <td>{{item.solicitud_up}}</td>
              <td>
                <span *ngIf="item.estado == -1" class="badge bg-danger">Cancelada</span>
                <span *ngIf="item.estado == 3" class="badge bg-success">Procesada</span>
                <span *ngIf="item.estado == 2" class="badge bg-warning">Pendiente</span>
                <span *ngIf="item.estado == 1" class="badge bg-warning">Pendiente</span>
              </td>
            </tr> -->
          </tbody>
        </table>
      </div>       
    </div>    
  </div>
</section>