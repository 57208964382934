<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-8">
        <div class="row">
          <div class="col-6">
            <h1>
              Tarjeta de Reservas
              <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#nuevo"
                (click)="resetAll()"><i class="fa fa-plus"></i></button>
              <button class="btn btn-sm btn-secondary ml-1" type="button" data-bs-toggle="modal" data-bs-target="#galeriaMedios"
                (click)="resetAll()"><i class="fa fa-photo-video"></i></button>
              
                <button *ngIf="!cargando" type="button" class="btn btn-sm btn-primary ml-1" (click)="getList()">
                  <i class="fas fa-sync"></i>
                </button>
                <button *ngIf="cargando" type="button" class="btn btn-sm btn-primary ml-1">
                  <i class="fa fa-sync-alt fa-spin"></i>
                </button>

            </h1>
          </div>
          <div class="col-6">
            <div class="input-group mb-0">
              <input type="text" class="form-control" [(ngModel)]="findInput" placeholder="Buscador">
              <button class="btn btn-secondary" type="button" id="button-addon2" (click)="findTarjeta()"><i
                  class="fa fa-search"></i></button>
            </div>
          </div>
        </div>

      </div>
      <div class="col-sm-4">
        <ol class="breadcrumb float-sm-right">

          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item active">Tarjeta de reservas</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content m-2">
  <div class="card">
    <div class="card-body p-0 ">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">NOMBRE</th>
            <th scope="col">FECHA VIAJE</th>
            <th scope="col">CONTACTO</th>
            <th scope="col">VISTO</th>
            <th scope="col">Estado</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of tarjeta_reserva.items">
            <td>{{item.id}}</td>
            <td style="cursor: pointer;" (click)="ver(item)">{{item.nombre | titlecase}}</td>
            <td>{{item.fecha_llegada}}</td>
            <td>{{item.celular}}</td>
            <td class="text-primary"><i class="fa fa-check-double"></i> <b class="ml-1">{{item.visto}}</b></td>
            <td><span class="badge bg-success" *ngIf="item.estado ==1">Activo</span></td>
            <td>
              <div class="dropdown">
                <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"></button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><span class="dropdown-item" style="cursor: pointer;" (click)="ver(item)">Ver Tarjeta</span></li>
                  <li><span class="dropdown-item"><a href="reserva/{{item.id}}" target="_blank"
                        style="color: #000;">Tarjeta Reserva Publica</a></span></li>
                  <li><span class="dropdown-item" *ngIf="user.perfil =='2fk6i2o3143'"
                      style="cursor: pointer;" (click)="ocultarTarjeta(item)">Ocultar Tarjeta</span></li>
                  <li><span class="dropdown-item text-danger" *ngIf="user.perfil =='2fk6i2o3143'"
                      style="cursor: pointer;" (click)="eliminarTarjeta(item)">Eliminar</span></li>
                </ul>
              </div>

            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- paginador -->
  <div class="card-footer clearfix">
    <ul class="pagination pagination-sm m-0 float-right">
      <li class="page-item" *ngIf="tarjeta_reserva.current > 1">
        <button type="button" class="page-link" (click)="changePage(tarjeta_reserva.current - 1)">
          <span>Atras</span>
        </button>
      </li>

      <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': tarjeta_reserva.current == page}">
        <button type="button" class="page-link" (click)="changePage(page)">
          {{ page }}
        </button>
      </li>

      <li class="page-item" *ngIf="tarjeta_reserva.current < tarjeta_reserva.total_page">
        <button type="button" class="page-link" (click)="changePage(tarjeta_reserva.next)">
          <span>Siguiente</span>
        </button>
      </li>
    </ul>
    <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
      <p>Total registros encontrados: <b>{{tarjeta_reserva.size}}</b></p>
    </div>
  </div>
  <!-- Fin paginador -->

</section>



<!-- Modal nuevo  -->
<div class="modal fade" id="nuevo" tabindex="-1" aria-labelledby="nuevoLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="nuevoLabel">Tarjeta Reserva</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="card">
          <h5 class="card-header">
            <button class="btn btn-sm btn-secondary" type="button" data-bs-toggle="modal"
              data-bs-target="#buscarClienteModal"><i class="fa fa-plus"></i></button> <span class="ml-2"
              data-bs-toggle="modal" data-bs-target="#buscarClienteModal" style="cursor: pointer;">Seleccione
              Cliente</span>
          </h5>
          <!-- CLIENTE -->
          <div class="card-body">
            <div class="row">
              <form [formGroup]="nuevoForm" (ngSubmit)="nuevoTarjeta()">
                <div class="row">
                  <div class="col-4">
                    <div class="form-floating mb-3">
                      <input formControlName="nombre" type="text" readonly class="form-control" placeholder=">Nombre">
                      <label>Nombre</label>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-floating mb-3">
                      <input formControlName="cc" type="text" readonly class="form-control" placeholder=">Nit. / C.C">
                      <label>Nit. / C.C</label>
                    </div>

                  </div>

                  <div class="col-4">
                    <div class="form-floating mb-3">
                      <input formControlName="email" type="text" readonly class="form-control" placeholder="Email">
                      <label>Email</label>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-floating mb-3">
                      <input formControlName="direccion" type="text" readonly class="form-control"
                        placeholder=">Dirección">
                      <label>Dirección</label>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-floating mb-3">
                      <input formControlName="celular" type="text" readonly class="form-control"
                        placeholder=">Teléfono">
                      <label>Teléfono</label>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>


        <div>
          <button class="btn btn-lg btn-block btn-primary" type="button" (click)="nuevoTarjeta()">Siguiente</button>
        </div>

      </div>
    </div>
  </div>
</div>


<!-- Modal buscar y crear CLIENTES -->
<div class="modal fade" id="buscarClienteModal" tabindex="-1" aria-labelledby="buscarClienteModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Seleccione Cliente</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#nuevo"
          aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label">Buscar cliente</label>
              <input [(ngModel)]="buscador" type="text" (keydown)="getBuscarCliente()" class="form-control">
            </div>

            <div *ngIf="!buscador"></div>

            <ul class="list-group" *ngIf="buscador">
              <!-- <button *ngFor="let item of listClientes | filtroCliente:buscador" type="button"
                class="list-group-item list-group-item-action" (click)="selectCliente(item)" data-bs-dismiss="modal"
                data-bs-toggle="modal" data-bs-target="#nuevo">
                <div class="mb-2 fw-bold">{{ item.nombre | titlecase }}</div>
                <div class="d-flex w-100 ">
                  <small><i class="fa fa-envelope"></i> {{item.email}}</small>
                  <small><i class="fa fa-mobile-alt ml-4"></i> {{item.celular}}</small>
                </div>
              </button> -->

              <button *ngFor="let item of listClientes" type="button"
                class="list-group-item list-group-item-action" (click)="selectCliente(item)" data-bs-dismiss="modal"
                data-bs-toggle="modal" data-bs-target="#nuevo">
                <div class="mb-2 fw-bold">{{ item.nombre | titlecase }}</div>
                <div class="d-flex w-100 ">
                  <small><i class="fa fa-envelope"></i> {{item.email}}</small>
                  <small><i class="fa fa-mobile-alt ml-4"></i> {{item.celular}}</small>
                </div>
              </button>

              <button class="list-group-item list-group-item-action text-bold mb-2 mt-2"
                style="background:#0d6efd; color:white;font-size: 1.0em" (click)="createCliente(buscador)">
                <i class="fa fa-user-plus"> </i> CREA UN CLIENTE.
              </button>
            </ul>
          </div>
          <form [formGroup]="nuevoClienteForm" (ngSubmit)="newCliente()">
            <div class="col-12 mt-3" id="crearCliente" style="display: none;">
              <div class="card">
                <div class="card-body">
                  <h5>Cliente</h5>
                  <div class="form-group">
                    <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control" />
                  </div>

                  <div class="form-group">
                    <input formControlName="cc" placeholder="Nit. - C.C" type="text" class="form-control" />
                  </div>

                  <div class="form-group">
                    <input formControlName="email" placeholder="Email" type="text" class="form-control" />
                  </div>

                  <div class="form-group">
                    <input formControlName="direccion" placeholder="Direccion" type="text" class="form-control" />
                  </div>

                  <div class="form-group">
                    <input formControlName="celular" placeholder="Celular" type="text" class="form-control" />
                  </div>

                  <app-button
                    [disabled]="!nuevoClienteForm.value.nombre || !nuevoClienteForm.value.celular || !nuevoClienteForm.value.email"
                    [type]="'submit'" [block]="true" [loading]="isAuthLoading">
                    Crear Cliente
                  </app-button>
                </div>
              </div>
            </div>
          </form>
          <div class="col-12">
            <button type="button" class="btn btn-secondary btn-block" data-bs-dismiss="modal" data-bs-toggle="modal"
              data-bs-target="#nuevo"><i class="fa fa-chevron-left"></i> Atrás</button>
          </div>


        </div>

      </div>
    </div>
  </div>
</div>


<!-- Modal buscar y crear PASAJEROS -->
<div class="modal fade" id="buscarPasajeroModal" tabindex="-1" aria-labelledby="buscarPasajeroModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Seleccione Pasajero</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#ver"
        aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label">Buscar Pasajero</label>
              <input [(ngModel)]="buscador" (keydown)="getBuscarPasajero()" type="text" class="form-control">
            </div>

            <div *ngIf="!buscador"></div>
            <ul class="list-group" *ngIf="buscador">
              <!-- <button *ngFor="let item of listPasajeros | filtroCliente:buscador" type="button"
                class="list-group-item list-group-item-action d-flex w-100 justify-content-between"
                (click)="selectPasajero(item)">
                <span>{{ item.nombre | titlecase }}</span> <small>{{item.adulto | uppercase}}</small>
              </button> -->
              <button *ngFor="let item of listPasajeros" type="button"
                class="list-group-item list-group-item-action d-flex w-100 justify-content-between"
                (click)="selectPasajero(item)">
                <span>{{ item.nombre | titlecase }}</span> <small>{{item.adulto | uppercase}}</small>
              </button>
            </ul>

            <h5 class="mt-2 mb-2">Pasajeros Agregados {{selectPasajeros.length}}</h5>
            <button *ngIf="selectPasajeros.length ==0" class="list-group-item list-group-item-action text-bold mb-2"
              style="background:#343a40cc; color:white;font-size: 1.0em" (click)="createPasajero(buscador)">
              <i class="fa fa-user-plus"> </i> CREA UN PASAJERO.
            </button>

            <button *ngIf="selectPasajeros.length >=1" class="list-group-item list-group-item-action text-bold mb-2"
              style="background:#ff9100cc; color:white;font-size: 1.0em" (click)="createPasajero(buscador)">
              <i class="fa fa-user-plus"> </i> CREA OTRO PASAJERO.
            </button>

          </div>
          <form [formGroup]="nuevoPasajeroForm" (ngSubmit)="newPasajero()">
            <div class="col-12 mt-3" id="crearPasajero" style="display: none;">
              <div class="card">
                <div class="card-body">
                  <h5>Pasajero</h5>

                  <div class="form-group">
                    <div class="form-check">
                      <input class="form-check-input" type="radio" value="adulto" formControlName="adulto"
                        id="flexRadioDefault1" checked />
                      <label class="form-check-label" for="flexRadioDefault1">
                        Adultos
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" value="niño" formControlName="adulto"
                        id="flexRadioDefault2" />
                      <label class="form-check-label" for="flexRadioDefault2">
                        Niños
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" value="infante" formControlName="adulto"
                        id="flexRadioDefault2" />
                      <label class="form-check-label" for="flexRadioDefault2">
                        Infantes
                      </label>
                    </div>
                  </div>

                  <div class="form-floating mb-3">
                    <input formControlName="nombre" type="text" class="form-control" placeholder=">Nombre">
                    <label>Nombre</label>
                  </div>

                  <div class="form-floating mb-3">
                    <input formControlName="cc" type="text" class="form-control" placeholder="Nit. / C.C">
                    <label>Nit. / C.C</label>
                  </div>

                  <div class="form-floating mb-3">
                    <input formControlName="fecha_nacimiento" type="date" class="form-control"
                      placeholder="Fecha nacimiento">
                    <label>Fecha nacimiento</label>
                  </div>

                  <div class="form-floating mb-3">
                    <input formControlName="nota" type="text" class="form-control" placeholder="Nota">
                    <label>Nota</label>
                  </div>

                  <app-button
                    [disabled]="!nuevoPasajeroForm.value.adulto || !nuevoPasajeroForm.value.nombre || !nuevoPasajeroForm.value.cc || !nuevoPasajeroForm.value.fecha_nacimiento"
                    [type]="'submit'" [block]="true" [loading]="isAuthLoading">
                    Crear Pasajero
                  </app-button>
                </div>
              </div>
            </div>
          </form>
          <div class="col-12">
            <button *ngIf="!tarjetaEditar" type="button" class="btn btn-secondary btn-block" data-bs-dismiss="modal"
              data-bs-toggle="modal" data-bs-target="#nuevo"><i class="fa fa-chevron-left"></i> Atrás</button>
            <button *ngIf="tarjetaEditar" type="button" class="btn btn-secondary btn-block" data-bs-dismiss="modal"
              data-bs-toggle="modal" data-bs-target="#ver"><i class="fa fa-chevron-left"></i> Atrás</button>
          </div>


        </div>

      </div>
    </div>
  </div>
</div>


<!-- EDITAR PASAJERO -->
<div class="modal fade" id="editarPasajeroModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="editarPasajeroModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Editar Pasajero</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#ver"
          aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <form [formGroup]="editPasajeroForm" (ngSubmit)="editPasajero()">
            <div class="col-12 mt-3" id="crearPasajero" style="display: block;">
              <div class="">
                <div class="">

                  <div class="mb-3">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" value="adulto" id="inlineRadio1" formControlName="adulto">
                      <label class="form-check-label" for="inlineRadio1">Adulto</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" value="niño" id="inlineRadio2" formControlName="adulto">
                      <label class="form-check-label" for="inlineRadio2">Niño</label>
                    </div>  
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" value="infante" id="inlineRadio3" formControlName="adulto">
                      <label class="form-check-label" for="inlineRadio3">Infante</label>
                    </div>
                  </div>


                  <div class="form-group">
                    <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control" />
                  </div>

                  <div class="form-group">
                    <input formControlName="cc" placeholder="Nit. / C.C" type="text" class="form-control" />
                  </div>



                  <div class="form-group">
                    <input formControlName="fecha_nacimiento" placeholder="fecha nacimiento" type="date"
                      class="form-control" />
                  </div>

                  <div class="form-group">
                    <input formControlName="nota" placeholder="Nota" type="text" class="form-control" />
                  </div>


                  <app-button
                    [disabled]="!editPasajeroForm.value.adulto || !editPasajeroForm.value.nombre || !editPasajeroForm.value.cc || !editPasajeroForm.value.fecha_nacimiento"
                    [type]="'submit'" [block]="true" [loading]="isAuthLoading">
                    Editar Pasajero
                  </app-button>
                </div>
              </div>
            </div>
          </form>
          <div class="col-12 mt-2">
            <button type="button" class="btn btn-secondary btn-block" data-bs-dismiss="modal" data-bs-toggle="modal"
              data-bs-target="#ver"><i class="fa fa-chevron-left"></i> Atrás</button>
          </div>


        </div>

      </div>
    </div>
  </div>
</div>


<!-- Modal buscar y crear VUELOS -->
<div class="modal fade" id="buscarVueloModal" tabindex="-1" aria-labelledby="buscarVueloModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Agregar Vuelo</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#nuevo"
          aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">

            <form [formGroup]="nuevoVueloForm" (ngSubmit)="newVuelo()">
              <div class="col-12 mt-3" id="crearVuelo" style="display: block;">
                <div class="card">
                  <div class="card-body">
                    <h5>Vuelos</h5>
                    <div class="row">
                      <div class="col-md-10">
                        <div class="row">
                          <div class="col-md-2">
                            <div class="form-group">
                              <input formControlName="cia" placeholder="CIA" type="text"
                                style="text-transform: uppercase;" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <input formControlName="vuelo" placeholder="Vuelo" type="number" class="form-control" />
                            </div>
                          </div>

                          <div class="col-md-2">
                            <div class="form-group">
                              <input formControlName="dia" placeholder="Día" type="number" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <select class="form-select" formControlName="mes" placeholder="Mes">
                                <option value="ENE">ENE</option>
                                <option value="FEB">FEB</option>
                                <option value="MAR">MAR</option>
                                <option value="ABR">ABR</option>
                                <option value="MAY">MAY</option>
                                <option value="JUN">JUN</option>
                                <option value="JUL">JUL</option>
                                <option value="AGO">AGO</option>
                                <option value="SEP">SEP</option>
                                <option value="OCT">OCT</option>
                                <option value="NOV">NOV</option>
                                <option value="DIC">DIC</option>
                              </select>

                            </div>
                          </div>

                        </div>
                        <div class="row">
                          <div class="col-md-2">
                            <div class="form-group">

                              <input formControlName="origen" placeholder="Origen" type="text" class="form-control" />
                              <ul class="list-group" *ngIf="nuevoVueloForm.value.origen" id="iataOrigen"
                                style="z-index: 10000;position: absolute;width: 160px;">
                                <button *ngFor="let item of listIata | filtroIata:nuevoVueloForm.value.origen"
                                  type="button" class="list-group-item list-group-item-action"
                                  (click)="selectVueloOrigen(item)">
                                  {{ item.codigo }}
                                </button>
                              </ul>
                            </div>
                          </div>

                          <div class="col-md-2">
                            <div class="form-group">
                              <input formControlName="destino" placeholder="Destino" type="text" class="form-control" />
                              <ul class="list-group" *ngIf="nuevoVueloForm.value.destino" id="iataDestino"
                                style="z-index: 10000;position: absolute;width: 160px;">
                                <button *ngFor="let item of listIata | filtroIata:nuevoVueloForm.value.destino"
                                  type="button" class="list-group-item list-group-item-action"
                                  (click)="selectVueloDestino(item)">
                                  {{ item.codigo }}
                                </button>
                              </ul>
                            </div>
                          </div>

                          <div class="col-md-2">
                            <div class="form-group">
                              <input formControlName="sale" placeholder="Sale" type="time" class="form-control" />
                            </div>
                          </div>


                          <div class="col-md-2">
                            <div class="form-group">
                              <input formControlName="llega" placeholder="Llega" type="time" class="form-control" />
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <input formControlName="clase" placeholder="Record" type="text" class="form-control" />
                            </div>
                          </div>

                        </div>

                      </div>
                      <div class="col-md-2">
                        <button
                          [disabled]="!nuevoVueloForm.value.cia || !nuevoVueloForm.value.origen || !nuevoVueloForm.value.destino"
                          class="btn bnt-lg btn-primary" type="submit" style="height: 100px;">Agregar Vuelo</button>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
            </form>

          </div>

          <div class="col-12">
            <button *ngIf="!tarjetaEditar" type="button" class="btn btn-secondary btn-block" data-bs-dismiss="modal"
              data-bs-toggle="modal" data-bs-target="#nuevo"><i class="fa fa-chevron-left"></i> Atrás</button>
            <button *ngIf="tarjetaEditar" type="button" class="btn btn-secondary btn-block" data-bs-dismiss="modal"
              data-bs-toggle="modal" data-bs-target="#ver"><i class="fa fa-chevron-left"></i> Atrás</button>
          </div>


        </div>

      </div>
    </div>
  </div>
</div>

<!-- Modal buscar PROVEEDOR ACTIVIDAD -->
<div class="modal fade" id="buscarActividadModal" tabindex="-1" aria-labelledby="buscarActividadModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Agregar Proveedor</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#nuevo"
          aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12" id="proveedorList">

            <div class="form-group">
              <label class="form-label">Buscar Proveedor</label>
              <input [(ngModel)]="buscador" type="text" class="form-control">
            </div>

            <ul class="list-group" *ngIf="buscador">
              <button *ngFor="let item of listProveedores | filtroProveedorActividad:buscador" type="button"
                class="list-group-item list-group-item-action" (click)="selectProveedor(item)">
                <div class="row">
                  <div class="col-md-12">{{ item.nombre | titlecase }}</div>
                </div>
              </button>
            </ul>

            <button type="button" class="btn btn-secondary btn-block" data-bs-dismiss="modal" data-bs-toggle="modal"
              data-bs-target="#nuevo"><i class="fa fa-chevron-left"></i> Atrás</button>

          </div>

          <div id="proveedorActividadList" style="display: none;">
            <ul class="list-group">
              <button *ngFor="let item of listProveedoresActividades" type="button"
                class="list-group-item list-group-item-action" (click)="selectProveedorActividad(item)">
                <div class="row">
                  <div class="col-md-12">{{ item.nombre | titlecase }}</div>
                </div>
              </button>

            </ul>

            <div *ngIf="listProveedoresActividades?.length === 0"
              style="text-align: center;margin-bottom: 3%;margin-top: 3%;">
              <div>No hay actividades con este proveedor </div>
            </div>
            <button class="btn btn-secondary btn-block" type="button" (click)="atrasBuscarProveedor()">IR ATRAS</button>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>


<!-- Modal VER  -->
<div class="modal fade" id="ver" tabindex="-1" aria-labelledby="verLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header" [ngClass]="{'bg-warning': contacto_nombre != contacto_nombre_borrador ? 'bg-warning ': contacto_celular != contacto_celular_borrador ? 'bg-warning': fecha_out != fecha_out_borrador ? 'bg-warning': fecha_llegada != fecha_llegada_borrador ? 'bg-warning': relacion_hoteles != relacion_hoteles_borrador ? 'bg-warning': hotel != hotel_borrador ? 'bg-warning': adulto != adulto_borrador ? 'bg-warning ': nino != nino_borrador ? 'bg-warning': infante != infante_borrador ? 'bg-warning': adulto_tarifa != adulto_tarifa_borrador ? 'bg-warning ': nino_tarifa != nino_tarifa_borrador ? 'bg-warning': infante_tarifa != infante_tarifa_borrador}">
        <h5 class="modal-title" id="nuevoLabel">Tarjeta Reserva <b>{{tarjeta_reserva_id}}</b> <span class="ml-5 text-danger" *ngIf="contacto_nombre != contacto_nombre_borrador || contacto_celular != contacto_celular_borrador || fecha_out != fecha_out_borrador || fecha_llegada != fecha_llegada_borrador || relacion_hoteles != relacion_hoteles_borrador || hotel != hotel_borrador || adulto != adulto_borrador || nino != nino_borrador || infante != infante_borrador || adulto_tarifa != adulto_tarifa_borrador || nino_tarifa != nino_tarifa_borrador || infante_tarifa != infante_tarifa_borrador"><i class="fa fa-exclamation-triangle"></i> No se han guardado los cambios </span></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>

      <div class="modal-body">
        <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#interesados"
              type="button" role="tab" aria-controls="interesados" aria-selected="true">GENERAL</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#proceso" type="button"
              role="tab" aria-controls="proceso" aria-selected="false">ABONOS</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#adjuntos" type="button"
              role="tab" aria-controls="adjuntos" aria-selected="false">ADJUNTOS</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#politicas" type="button"
              role="tab" aria-controls="politicas" aria-selected="false">POLITICAS</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="apariencia-tab" data-bs-toggle="tab" data-bs-target="#apariencia" type="button"
              role="tab" aria-controls="apariencia" aria-selected="false">APARIENCIA</button>
          </li>
        </ul>


        <div class="tab-content" id="myTabContent">
          <!-- TAB GENERAL -->
          <div class="tab-pane fade show active  bg-body" id="interesados" role="tabpanel"
            aria-labelledby="recientes-tab">

            <!-- CONTACTO GENERAL -->
            <div class="card">
              <h5 class="card-header">
                Cliente
                <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="modal"
                  data-bs-target="#buscarClienteModal"><i class="fa fa-plus"></i></button>
              </h5>
              <!-- CLIENTE -->
              <div class="card-body">
                <div class="row">
                  <form [formGroup]="editForm" (ngSubmit)="editarTarjeta()">
                    <div class="row">
                      <!-- <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Actualizar Cliente</app-button>                        -->
                      <div class="col-4">
                        <div class="form-floating mb-3">
                          <input formControlName="nombre" type="text" readonly class="form-control"
                            placeholder=">Nombre">
                          <label>Nombre</label>
                        </div>
                      </div>

                      <div class="col-4">
                        <div class="form-floating mb-3">
                          <input formControlName="cc" type="text" readonly class="form-control"
                            placeholder=">Nit. / C.C">
                          <label>Nit. / C.C</label>
                        </div>

                      </div>

                      <div class="col-4">
                        <div class="form-floating mb-3">
                          <input formControlName="email" type="text" readonly class="form-control" placeholder="Email">
                          <label>Email</label>
                        </div>
                      </div>

                      <div class="col-4">
                        <div class="form-floating mb-3">
                          <input formControlName="direccion" type="text" readonly class="form-control"
                            placeholder=">Dirección">
                          <label>Dirección</label>
                        </div>
                      </div>

                      <div class="col-4">
                        <div class="form-floating mb-3">
                          <input formControlName="celular" type="text" readonly class="form-control"
                            placeholder=">Teléfono">
                          <label>Teléfono</label>
                        </div>
                      </div>

                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="card" [ngClass]="{'border-warning': contacto_nombre != contacto_nombre_borrador ? 'border-warning ': contacto_celular != contacto_celular_borrador}">
              <h5 class="card-header">Contacto de Emergencia
                <button [ngClass]="{'btn-warning': contacto_nombre != contacto_nombre_borrador ? 'btn-warning ': contacto_celular != contacto_celular_borrador}" class="btn btn-sm btn-secondary" type="button" *ngIf="!cargando" (click)="editarTarjeta()"><i
                    class="fa fa-sync"></i></button>
                <button class="btn btn-warning" type="button" *ngIf="cargando"><span
                    class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
              </h5>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-floating mb-3">
                      <input [(ngModel)]="contacto_nombre" type="text" class="form-control"
                        placeholder="Contacto Nombre">
                      <label>Contacto Nombre</label>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-floating mb-3">
                      <input [(ngModel)]="contacto_celular" type="text" class="form-control"
                        placeholder="Contacto Celular">
                      <label>Contacto Celular</label>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-floating mb-3">
                      <select class="form-select" [(ngModel)]="referido" placeholder="Referido">
                        <option *ngFor="let item of listAsesores" [value]="item.id">{{item.nombre | titlecase}}</option>
                      </select>
                      <label for="floatingSelect">Referido</label>
                    </div>

                  </div>

                </div>
              </div>
            </div>

            <!-- PASAJEROS -->
            <div class="card">
              <h5 class="card-header">
                <button class="btn btn-sm btn-secondary" type="button" data-bs-toggle="modal"
                  data-bs-target="#buscarPasajeroModal"><i class="fa fa-plus"></i></button>
                Pasajeros
              </h5>
              <div class="card-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">NOMBRES Y APELLIDOS</th>
                      <th scope="col">INDENTIFICACIÓN</th>
                      <th scope="col" class="text-center">ADL / NIÑO / INFANTE</th>
                      <th scope="col">FECHA NAC.</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of selectPasajeros; let i=index">
                      <th scope="row">{{i+1}}</th>
                      <td >{{item.nombre | titlecase}}</td>
                      <td>{{item.cc}}</td>
                      <td class="text-center">{{item.adulto | uppercase}}</td>
                      <td>{{item.fecha_nacimiento}}</td>
                      <td>
                        <div class="btn-group">
                        <button class="btn btn-sm btn-outline-secondary" (click)="selectEditarPasajero(item)"><i
                            class="fa fa-edit"></i></button>
                        <button class="btn btn-sm btn-outline-danger" (click)="deletePasajeros(i,item)"><i
                            class="fa fa-trash"></i></button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>

            <!-- VUELOS -->
            <div class="card">
              <h5 class="card-header">
                <button class="btn btn-sm btn-secondary" type="button" data-bs-toggle="modal"
                  data-bs-target="#buscarVueloModal"><i class="fa fa-plus"></i></button>
                Vuelos
              </h5>
              <div class="card-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">CIA</th>
                      <th scope="col">VUELO</th>
                      <th scope="col">DESDE</th>
                      <th scope="col">HASTA</th>
                      <th scope="col">DIA</th>
                      <th scope="col">MES</th>
                      <th scope="col">SALE</th>
                      <th scope="col">LLEGA</th>
                      <th scope="col">RECORD</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of selectVuelos; let i=index">
                      <th scope="row">{{i+1}}</th>
                      <td>{{item.cia | uppercase}}</td>
                      <td>{{item.vuelo}}</td>
                      <td>{{item.origen}}</td>
                      <td>{{item.destino}}</td>
                      <td>{{item.dia}}</td>
                      <td>{{item.mes}}</td>
                      <td>{{item.sale}}</td>
                      <td>{{item.llega}}</td>
                      <td>{{item.clase}}</td>
                      <td>
                        <button class="btn btn-sm btn-outline-danger" (click)="deleteVuelos(i,item)">
                          <i class="fa fa-trash"></i></button>
                      </td>
                    </tr>

                  </tbody>
                </table>

              </div>
            </div>

            <!-- PROVEEDORES -->
            <div class="card">
              <h5 class="card-header">
                <button class="btn btn-sm btn-secondary" type="button" data-bs-toggle="modal"
                  data-bs-target="#buscarActividadModal"><i class="fa fa-plus"></i></button> Proveedores
              </h5>
              <div class="card-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">PROVEEDOR</th>
                      <th scope="col">ACTIVIDAD</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of selectProveedorActividades; let i=index">
                      <th scope="row">{{i+1}}</th>
                      <td>{{item.proveedor | titlecase}} {{item.proveedor_ciudad | titlecase}}</td>
                      <td>{{item.nombre}}</td>
                      <td>
                        <button class="btn btn-sm btn-outline-danger" (click)="deleteProveedorActividades(i,item)"><i
                            class="fa fa-trash"></i></button>
                      </td>
                    </tr>

                  </tbody>
                </table>

              </div>
            </div>

            <!-- ALOJAMIENTO -->
            <div class="card" [ngClass]="{'border-warning': fecha_out != fecha_out_borrador ? 'border-warning': fecha_llegada != fecha_llegada_borrador ? 'border-warning': relacion_hoteles != relacion_hoteles_borrador ? 'border-warning': hotel != hotel_borrador ? 'border-warning': adulto != adulto_borrador ? 'border-warning ': nino != nino_borrador ? 'border-warning': infante != infante_borrador}">
              <h5 class="card-header">
                Alojamiento
                <button [ngClass]="{'btn-warning': fecha_out != fecha_out_borrador ? 'btn-warning': fecha_llegada != fecha_llegada_borrador ? 'btn-warning': relacion_hoteles != relacion_hoteles_borrador ? 'btn-warning': hotel != hotel_borrador ? 'btn-warning': adulto != adulto_borrador ? 'btn-warning ': nino != nino_borrador ? 'btn-warning': infante != infante_borrador}" class="btn btn-sm btn-secondary" type="button" *ngIf="!cargando" (click)="editarTarjeta()"><i
                    class="fa fa-sync"></i></button>
                <button class="btn btn-warning" type="button" *ngIf="cargando"><span
                    class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
              </h5>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">

                    <div class="row">
                      <div class="col-12">
                        <div class="form-floating mb-3">
                          <input [(ngModel)]="hotel" type="text" class="form-control" placeholder=">Hotel">
                          <label>Plan</label>
                        </div>
                      </div>
                      <div class="col-8">
                        <div class="form-floating mb-3">
                          <input [(ngModel)]="fecha_llegada" type="date" class="form-control"
                            placeholder=">Fecha Llegada" (change)="calDias(fecha_llegada,fecha_out)">
                          <label>Check IN</label>
                        </div>

                        <div class="form-floating mb-3">
                          <input [(ngModel)]="fecha_out" type="date" class="form-control" placeholder=">Fecha Salida"
                            (change)="calDias(fecha_llegada,fecha_out)">
                          <label>Check OUT</label>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-floating mb-3">
                          <input [(ngModel)]="noches" type="text" class="form-control form-control-lg text-center"
                            style="font-size: 2em;height: 130px;" placeholder=">Noches" disabled>
                          <label>Noches</label>
                        </div>
                      </div>
                    </div>



                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-floating mb-3">
                          <input [(ngModel)]="adulto" type="text" type="number" min="1" pattern="^[0-9]+"
                            class="form-control" placeholder=">Adultos">
                          <label>Adultos</label>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-floating mb-3">
                          <input [(ngModel)]="nino" type="text" type="number" min="1" pattern="^[0-9]+"
                            class="form-control" placeholder=">Niños">
                          <label>Niños</label>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-floating mb-3">
                          <input [(ngModel)]="infante" type="text" type="number" min="1" pattern="^[0-9]+"
                            class="form-control" placeholder=">Infante">
                          <label>Infantes</label>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <div class="form-floating mb-3">
                          <textarea class="form-control" [(ngModel)]="relacion_hoteles" placeholder="Relación Hoteles"
                          style="height: 190px"></textarea>
                        <label for="floatingTextarea2">Relación Hoteles</label>  
                        </div>
                      </div>                      
                    </div>

                    <!-- <div id="newAcomodacion" style="display: none;">
                      <div class="card">
                        <div class="card-body">
                          <div>
                            <h5 class="text-center">Acomodación
                              <span *ngIf="tipoAcomodacion==1">Sencilla</span>
                              <span *ngIf="tipoAcomodacion==2">Doble</span>
                              <span *ngIf="tipoAcomodacion==3">Triple</span>
                              <span *ngIf="tipoAcomodacion==4">Cuádruple</span>
                            </h5>
                          </div>
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">NOMBRES Y APELLIDOS</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of selectPasajerosAcomodacion; let i=index">
                                <th scope="row">{{i+1}}</th>
                                <td>{{item.nombre | titlecase}}</td>
                                <td><button class="btn btn-sm btn-outline-primary"
                                    (click)="selectPasajeroAcomodacion(item,i,tipoAcomodacionI)"><i
                                      class="fa fa-plus"></i></button></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div> -->

                  </div>

                  <div class="col-md-8">
                    <!-- plantilla acomodacion -->
                    <div class="card">
                      <div class="card-body">
                        <div class="row mb-3">
                          <div class="col-md-3"><button class="btn btn-lg btn-block btn-outline-primary"
                              (click)="addAcomodacion(1)"><i class="fa fa-user"></i>
                              <h6>Sencilla</h6>
                            </button></div>
                          <div class="col-md-3"><button class="btn btn-lg btn-block btn-outline-secondary"
                              (click)="addAcomodacion(2)"><i class="fa fa-user-friends"></i>
                              <h6>Doble</h6>
                            </button></div>
                          <div class="col-md-3"><button class="btn btn-lg btn-block btn-outline-warning"
                              (click)="addAcomodacion(3)"><i class="fa fa-users"></i>
                              <h6>Triple</h6>
                            </button></div>
                          <div class="col-md-3"><button class="btn btn-lg btn-block btn-outline-info"
                              (click)="addAcomodacion(4)"><i class="fa fa-dice-four"></i>
                              <h6>Cuádruple</h6>
                            </button></div>
                        </div>

                        <!-- acomodacion modulo -->
                        <!-- {{selectCAcomodacion | json}} -->
                        <div class="" *ngFor="let item of selectCAcomodacion; let i=index">
                          <div class="card">
                            <div class="card-body">
                              <div class="row">
                              <div *ngIf="item.tipo ==1" class="col-md-3 mt-2">
                                <button class="btn btn-lg btn-block btn-primary"><i class="fa fa-user"></i>
                                  <h6>Sencilla</h6>
                                </button>
                                <!-- <button class="btn btn-sm btn-danger" *ngIf="item.pasajeros?.length >=0" (click)="deleteTipoAcomodacion(i)" type="button" style="position: absolute;left: 12px;top: 0px;padding-bottom: 0px;"><i class="fa fa-times"></i><h6></h6></button> -->
                              </div>
                              <div *ngIf="item.tipo ==2" class="col-md-3 mt-2">
                                <button class="btn btn-lg btn-block btn-secondary"><i class="fa fa-user-friends"></i>
                                  <h6>Doble</h6>
                                </button>
                                <!-- <button class="btn btn-sm btn-danger" *ngIf="item.pasajeros?.length >=0" (click)="deleteTipoAcomodacion(i)" type="button" style="position: absolute;left: 12px;top: 0px;padding-bottom: 0px;"><i class="fa fa-times"></i><h6></h6></button> -->
                              </div>
                              <div *ngIf="item.tipo ==3" class="col-md-3 mt-2">
                                <button class="btn btn-lg btn-block btn-warning"><i class="fa fa-users"></i>
                                  <h6>Triple</h6>
                                </button>
                                <!-- <button class="btn btn-sm btn-danger" *ngIf="item.pasajeros?.length >=0" (click)="deleteTipoAcomodacion(i)" type="button" style="position: absolute;left: 12px;top: 0px;padding-bottom: 0px;"><i class="fa fa-times"></i><h6></h6></button> -->
                              </div>
                              <div *ngIf="item.tipo ==4" class="col-md-3 mt-2">
                                <button class="btn btn-lg btn-block btn-info"><i class="fa fa-dice-four"></i>
                                  <h6>Cuádruple</h6>
                                </button>
                              </div>
                              <div class="col-md-9 mt-2">
                                <table class="table table-hover">
                                  <tbody>
                                    <tr *ngFor="let itemPasajero of item.pasajeros; let iP=index">
                                      <td>{{itemPasajero.nombre | titlecase}}</td>
                                      <td class="text-right">
                                        <!-- i = {{i}} iP = {{iP}} -->
                                        <button class="btn btn-sm btn-outline-danger" (click)="deletePasajeroAcomodacion(i,iP,itemPasajero.pasajero_id)"><i class="fa fa-trash"></i></button>
                                      </td>
                                    </tr>
    
                                  </tbody>
                                </table>
    
                                <div class="text-right mr-2">
                                  <div class="btn-group" role="group" aria-label="Basic example">
                                    <button type="button" class="btn btn-sm btn-secondary" (click)="addPasajeroAcomodacion(item.tipo,i)"><i class="fa fa-user-plus"></i> Agregar Pasajero</button>
                                    <button type="button" class="btn btn-sm btn-danger"*ngIf="item.pasajeros?.length <1" (click)="deleteTipoAcomodacion(i)"><i class="fa fa-times"></i> Eliminar Acomodación {{item.pasajeros?.length}}</button>
                                  </div>

                                </div>
                              </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- plantilla acomodacion -->
                  </div>
                </div>
              </div>
            </div>

<!-- Modal Pasajeros disponible -->
<div class="modal fade" id="pasajerosDisponibles" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Acomodación pasajeros</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div>
          <h5 class="text-center">Acomodación
            <span *ngIf="tipoAcomodacion==1">Sencilla</span>
            <span *ngIf="tipoAcomodacion==2">Doble</span>
            <span *ngIf="tipoAcomodacion==3">Triple</span>
            <span *ngIf="tipoAcomodacion==4">Cuádruple</span>
          </h5>
        </div>
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">NOMBRES Y APELLIDOS</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of selectPasajerosDisponibleAcomodacion; let i=index">
              <th *ngIf="!item.acomodacion" scope="row">{{i+1}}</th>
              <td *ngIf="!item.acomodacion">{{item.nombre | titlecase}} {{item.acomodacion}}</td>
              <td *ngIf="!item.acomodacion"><button class="btn btn-sm btn-outline-primary"
                  (click)="selectPasajeroAcomodacion(item,i,tipoAcomodacionI)"><i
                    class="fa fa-plus"></i></button></td>
            </tr>
          </tbody>
        </table>        

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>

            <!-- VALOR VENTA -->
            <div class="card" [ngClass]="{'border-warning': adulto_tarifa != adulto_tarifa_borrador ? 'border-warning ': nino_tarifa != nino_tarifa_borrador ? 'border-warning': infante_tarifa != infante_tarifa_borrador}">
              <div class="card-header d-flex w-100 justify-content-between">
                <h5>Valor Venta
                  <button [ngClass]="{'btn-warning': adulto_tarifa != adulto_tarifa_borrador ? 'btn-warning ': nino_tarifa != nino_tarifa_borrador ? 'btn-warning': infante_tarifa != infante_tarifa_borrador}"  class="btn btn-sm btn-secondary" type="button" *ngIf="!cargando" (click)="editarTarjeta()"><i
                      class="fa fa-sync"></i></button>
                  <button class="btn btn-warning" type="button" *ngIf="cargando"><span
                      class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
                </h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-floating mb-3">
                      <input [(ngModel)]="adulto_tarifa" currencyMask
                        [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}" class="form-control"
                        placeholder=">Tarifa Adulto" style="font-size: 1.8em;">
                      <label>Tarifa Adulto</label>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-floating mb-3">
                      <input [(ngModel)]="nino_tarifa" currencyMask
                        [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}" class="form-control"
                        placeholder=">Tarifa Niño" style="font-size: 1.8em;">
                      <label>Tarifa Niño</label>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-floating mb-3">
                      <input [(ngModel)]="infante_tarifa" currencyMask
                        [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}" class="form-control"
                        placeholder=">Tarifa Infante" style="font-size: 1.8em;">
                      <label>Tarifa Infante</label>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="text-center border p-2">
                      <h4 style="font-size: 1.8em;">
                        ${{adulto_tarifa * adulto + nino_tarifa * nino + infante_tarifa * infante | decimal}}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- CONSTEO -->
            <div class="card">
              <h5 class="card-header">
                <button class="btn btn-sm btn-secondary" type="button" (click)="createCosteo()"><i
                    class="fa fa-plus"></i></button>
                Costeo
              </h5>
              <div class="card-body">
                <form [formGroup]="nuevoCosteoForm" (ngSubmit)="newCosteo()">
                  <div class="row" id="editCosteo" style="display: none;">
                    <div class="col-md-6">
                      <div class="form-group">
                        <input formControlName="detalle" placeholder="Detalle" type="text" class="form-control" />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <input formControlName="cantidad" placeholder="Cantidad" type="number" class="form-control" />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="input-group">
                        <input formControlName="valor" placeholder="Valor" class="form-control" style="font-size:1.7em"
                          currencyMask [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}" />
                        <div class="input-group-prepend">
                          <app-button [disabled]="!nuevoCosteoForm.value.detalle || !nuevoCosteoForm.value.valor"
                            [type]="'submit'" [block]="true" [loading]="isAuthLoading">
                            <i class="fa fa-plus"></i>
                          </app-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">DETALLE </th>
                      <th scope="col" class="text-center">CANTIDAD </th>
                      <th scope="col">VALOR </th>
                      <th scope="col" class="text-right pr-5">V. TOTAL </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of selectCosteo; let i=index">
                      <th scope="row">{{i+1}}</th>
                      <td>{{item.detalle}} </td>
                      <td class="text-center">{{item.cantidad}} </td>
                      <td>${{item.valor | decimal }}</td>
                      <td class="text-right">
                        ${{item.valor * item.cantidad | decimal}}
                        <button class="btn btn-sm btn-outline-danger ml-2" (click)="deleteCosteo(i, item)"><i
                            class="fa fa-trash"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-md-4 mt-3 border" style="font-size: 1.8em;">
                    <div>${{adulto_tarifa * adulto + nino_tarifa * nino + infante_tarifa * infante | decimal}}</div>
                    <small>Total venta</small>
                  </div>
                  <div class="col-md-4 mt-3 text-center border" style="font-size: 1.8em;">
                    <div>${{adulto_tarifa * adulto + nino_tarifa * nino + infante_tarifa * infante - sumTotalCosteo | decimal}}</div>
                    <small>Diferencia</small>
                  </div>                  
                  <div class="col-md-4 text-right mt-3 border" style="font-size: 1.8em;">
                    <div *ngIf="tarjetaEditar">${{sumTotalCosteo | decimal}}</div>
                    <label>Total Costeo</label>
                  </div>
                </div>

              </div>
            </div>
            <!-- fin tab -->
          </div>

          <!-- TAB ABONOS -->
          <div class="tab-pane fade" id="proceso" role="tabpanel" aria-labelledby="proceso-tab">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4 text-center">
                    <h4><b>Total $</b><span *ngIf="total_venta">{{total_venta | decimal}}</span></h4>
                  </div>
                  <div class="col-md-4 text-center">
                    <h4><b>Abono $ <span *ngIf="abono_reserva">{{abono_reserva | decimal}}</span></b></h4>
                  </div>
                  <div class="col-md-4 text-center">
                    <h4><b>Saldo $ <span *ngIf="abono_reserva">{{total_venta - abono_reserva | decimal}}</span></b></h4>
                  </div>
                </div>
              </div>
            </div>

            <!-- HISTORIAL DE ABONOS -->
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <h4>Historial Abonos</h4>
                  </div>
                  <div class="col-md-12">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Fecha</th>
                          <th scope="col">Detalle</th>
                          <th scope="col">Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of listHistorialAbonos; let i=index">
                          <th scope="row">{{i+1}}</th>
                          <td>{{item.tarjeta_reserva_abono_at}}</td>
                          <td>
                            <a [href]="item.img_url" target="_blank" class="mr-1"><i class="fa fa-receipt"
                                *ngIf="item.img"></i></a>
                            {{item.detalle}}
                          </td>
                          <td class="text-right "><b>${{item.valor | decimal}}</b>
                            <div *ngIf="user.perfil =='2fk6i2o3143'" class="btn-group ml-3" role="group"
                              aria-label="Basic example">
                              <button type="button" class="btn btn-sm btn-outline-secondary"
                                (click)="abonoEdit(item)"><i class="fa fa-edit"></i></button>
                              <button type="button" class="btn btn-sm btn-outline-secondary"
                                  (click)="abonoDelete(item)"><i class="fa fa-trash"></i></button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <!-- CARGAR ABONO -->
            <div class="card">
              <div class="card-body" id="abonoPanel">
                <div class="row">
                  <div class="col-md-6 text-center">
                    <img *ngIf="!comprobante_url" src="assets/img/comprobante-default.png" (click)="fileEditar.click()"
                      class="img-fluid img-thumbnail" style="cursor: pointer;" />
                    <img *ngIf="comprobante_url" [src]="comprobante_url" (click)="fileEditar.click()"
                      class="img-fluid img-thumbnail" style="cursor: pointer;" />
                    <input #fileEditar type="file" accept='image/*' (change)="uploadImg(fileEditar.files,'nuevo')"
                      style="display: none" />
                    <small class="text-muted">Cargar comprobante</small>
                  </div>
                  <div class="col-md-6 text-left">
                    <div class="form-floating mb-2">
                      <textarea class="form-control" [(ngModel)]="detalle" placeholder="Detalle Abono"
                        style="height: 100px"></textarea>
                      <label for="floatingTextarea2">Detalle Abono</label>
                    </div>
                    <div class="form-floating mb-2">
                      <input [(ngModel)]="abono_valor" placeholder="Valor Abono" currencyMask
                        [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}" class="form-control"
                        style="font-size: 1.8em;" />
                      <label for="floatingNombre">Valor Abono <span class="text-danger">*</span></label>
                    </div>
                    <button [disabled]="!abono_valor" type="button" class="btn btn-block btn-primary"
                      (click)="setCrearAbono(abono_valor,tarjeta_reserva_id,comprobante,detalle)">
                      <span *ngIf="!editar_abono"><i class="fa fa-plus"></i> Agregar Abono</span> <span
                        *ngIf="editar_abono"><i class="fa fa-sync"></i> Actualizar Abono</span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- TAB ADJUNTO -->
          <div class="tab-pane fade" id="adjuntos" role="tabpanel" aria-labelledby="adjuntos-tab">
            <div class="row">
              <div class="col-4">
                <div class="row">
                  <div class="col-10">
                    <div class="form-floating mb-2">
                      <input [(ngModel)]="adjunto_nombre" placeholder="Nombre del adjunto" type="text"
                        class="form-control" />
                      <label for="floatingNombre">Nombre del adjunto <span class="text-danger">*</span></label>
                    </div>
                  </div>
                  <div class="col-2 p-0">
                    <button type="button" *ngIf="loadingImg" class="btn btn-block btn-primary p-3 m-0">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>

                    <button *ngIf="!loadingImg" [disabled]="!adjunto_nombre" class="btn btn-block btn-primary p-3 m-0 "
                      (click)="fileUploadAdjuntos.click()"><i class="fa fa-upload"></i></button>
                    <input #fileUploadAdjuntos type="file" accept='application/pdf, image/*'
                      (change)="uploadArchivo(fileUploadAdjuntos.files)" style="display: none" />
                  </div>
                </div>
                <small class="text-muted">Cargar Adjunto {{adjunto_archivo}}</small>
              </div>
              <div class="col-8">
                <div class="list-group">
                  <div class="list-group-item list-group-item-action" *ngFor="let item of listAdjuntos; let i=index">
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-1" style="cursor: pointer;"><a [href]="item.archivo_url"
                          target="_blank">{{item.nombre | titlecase}}</a></h5>
                      <small>
                          <b class="mr-3">{{item.extension}}</b> 
                          <button class="btn btn-sm btn-outline-primary" type="button" (click)="deleteAdjunto(item)"><i class="fa fa-trash"></i></button>
                      </small>
                    </div>
                    <small>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" [checked]="item.visibilidad == 1" (change)="adjuntoVisibilidad(item.id,$event)">
                        <label class="form-check-label" for="flexSwitchCheckDefault"><span *ngIf="item.visibilidad == 1">Público</span> <span *ngIf="item.visibilidad == 0">Oficina</span></label>
                      </div>
                      {{item.archivo}}
                      <div>{{item.tarjeta_reserva_adjunto_at}}</div>
                    </small>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!-- TAB POLITICAS -->
          <div class="tab-pane fade" id="politicas" role="tabpanel" aria-labelledby="politicas-tab">
            <div class="row">
              <div class="col-8">
                <div class="text-center">
                  <h4 class="text-center">Politicas tarjeta de reserva</h4>
                  <!-- <i class="fa fa-gavel" style="font-size: 9em;color: #ddd;margin-top: 15%;"></i> -->
                </div>

                <div class="list-group">
                  <div class="list-group-item list-group-item-action"
                    *ngFor="let item of listPoliticasTarjetaReserva; let i=index">
                    <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1" style="cursor: pointer;">{{item.nombre | titlecase}}</h6>
                      <small><button class="btn btn-sm btn-danger" type="button" (click)="deletePolitica(item)"><i
                            class="fa fa-trash"></i></button></small>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-4">
                <h5 class="text-center">Politicas Agencia</h5>
                <div class="list-group">
                  <div class="list-group-item list-group-item-action" *ngFor="let item of listPoliticas; let i=index">
                    <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1" style="cursor: pointer;">{{item.nombre | titlecase}}</h6>
                      <small><button class="btn btn-sm btn-primary" type="button" (click)="addPolitica(item)"><i
                            class="fa fa-plus"></i></button></small>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <!-- TAB APARIENCIA -->
          <div class="tab-pane fade" id="apariencia" role="tabpanel" aria-labelledby="apariencia-tab">
            <div class="row">
              <div class="col-md-8">
                <h5>Imagen Portada Reserva</h5>
                <img *ngIf="img_archivo_url" [src]="img_archivo_url" class="img-thumbnail img-fluid" style="cursor: pointer;">
              </div>
              <div class="col-md-4">
                <div class="row">
                  <h5>Seleccione imagen portada</h5>
                  <span *ngFor="let item of listGaleriaMedios; let i=index" class="col-md-6">
                    <img [src]="item.archivo_url" class="img-thumbnail img-fluid" style="cursor: pointer;" (click)="selectImagenApariencia(item)">
                  </span>                
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>



<!-- GALERIA MEDIOS -->
<div class="modal fade" id="galeriaMedios" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Galeria de Medios</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12" *ngIf="selectImagen?.id">
            <img [src]="selectImagen?.archivo_url" class="img-thumbnail img-fluid">
            <button type="button" class="mt-1 btn btn-danger" (click)="deleteImagenSelect(selectImagen)" style="position: absolute;top: 10px;right: 22px;"><i class="fa fa-trash"></i></button>
          </div>
          <div class="col-md-12 mb-3 text-center">
            <button type="button" class="btn btn-block btn-outline-secondary" (click)="fileEditarGaleriaMedios.click()"><i class="fa fa-upload"></i> Subir Archivo</button>
            <input #fileEditarGaleriaMedios type="file" accept='image/*' (change)="uploadImgGaleriaMedios(fileEditarGaleriaMedios.files)" style="display: none" />
          </div> 
          
          <span *ngFor="let item of listGaleriaMedios; let i=index" class="col-md-4">
            <img [src]="item.archivo_url" class="img-thumbnail img-fluid" style="cursor: pointer;" (click)="verImagenSelect(item)">
          </span>
        </div>       
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>