<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-6">
                <h1>
                  PROVEEDORES
                  <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNueva" aria-controls="offcanvasNueva" (click)="resetFormCampos()"><i class="fa fa-plus"></i></button>
                </h1>
              </div>
              <div class="col-6">
                <div class="input-group mb-0">
                  <input type="text" class="form-control" [(ngModel)]="findInput" placeholder="Buscador">
                  <button class="btn btn-secondary" type="button"  id="button-addon2" (click)="findBuscar()"><i class="fa fa-search"></i></button>
                </div>                  
              </div>
            </div>

          </div>
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">

                  <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                  <li class="breadcrumb-item active">Proveedores</li>
              </ol>
          </div>
      </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content m-2">
  <div class="card">
    <div class="card-body table-responsive p-0">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">NOMBRE</th>
            <th scope="col">DIRECCIÓN</th>
            <th scope="col">CIUDAD</th>
            <th scope="col">CONTACTO</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of proveedor.items">
            <td style="cursor: pointer;" (click)="ver(item)">{{item.nombre | titlecase}}</td>
            <td>{{item.direccion}}</td>
            <td>{{item.ciudad | titlecase}}</td>
            <td>{{item.contacto}}</td>
            <td>
              
              <div class="dropdown">
                <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"></button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><span class="dropdown-item"  style="cursor: pointer;" (click)="ver(item)">Ver Proveedor</span></li>
                  <li><span class="dropdown-item text-danger" style="cursor: pointer;" (click)="eliminar(item)">Eliminar</span></li>
                </ul>
              </div>           

            </td>
          </tr>
        </tbody>
      </table>      
    </div>
  </div>

  <!-- paginador -->
  <div class="card-footer clearfix">
    <ul class="pagination pagination-sm m-0 float-right">
      <li class="page-item" *ngIf="proveedor.current > 1">
        <button type="button" class="page-link" (click)="changePage(proveedor.current - 1)">
          <span>Atras</span>
        </button>
      </li>                  

      <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': proveedor.current == page}">
        <button type="button" class="page-link" (click)="changePage(page)">
          {{ page }}
        </button>
      </li>                  

      <li class="page-item" *ngIf="proveedor.current < proveedor.total_page">
        <button type="button" class="page-link" (click)="changePage(proveedor.next)">
          <span>Siguiente</span>
        </button>
      </li>                  
    </ul>
    <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
      <p>Total registros encontrados: <b>{{proveedor.size}}</b></p>
    </div>
  </div>              
  <!-- Fin paginador -->  
</section>




  <!-- Modal Ver  -->
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasVer" aria-labelledby="offcanvasVerLabel">
      <div class="offcanvas-header">
        <h5 id="offcanvasRightLabel">Proveedor</h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <form [formGroup]="editForm" (ngSubmit)="edit()">
          <div class="row">
            <div class="col-12 mt-3">
                <div class="form-group">
                  <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control"/>
                </div>
                <div class="form-group">
                  <input formControlName="direccion" placeholder="Dirección" type="text" class="form-control"/>
                </div>  
                <div class="form-group">
                  <input formControlName="ciudad" placeholder="Ciudad" type="text" class="form-control"/>
                </div>   
                <div class="form-group">
                  <input formControlName="contacto" placeholder="Contacto" type="text" class="form-control"/>
                </div> 
                
                
                <div class="row">

                  <div class="col-12">
                    <div class="card">
                      <div class="card-header">
                        Actividades <button type="button" class="btn btn-sm btn-secondary" (click)="addActividadEditar()"><i class="fa fa-plus"></i></button>
                      </div>
                      <div class="card-body">
                        <!-- agregar actividad Nueva -->
                        <div class="col-12" id="addActividadVerNew" style="display: none;">
                          <form [formGroup]="nuevoActividad" (ngSubmit)="newAddNew()">
                            <div class="card">
                              <div class="card-body">
                                <div class="row">
                                  <div class="col-10">
                                    <div class="form-group">
                                      <input formControlName="nombre" placeholder="Nombre actividad" type="text" class="form-control"/>
                                    </div>                                          
                                  </div>
                                  <div class="col-12">
                                    <div class="form-group">
                                      <textarea class="form-control" formControlName="incluye" rows="4" placeholder="Incluye"></textarea>
                                    </div>                                          
                                  </div>

                                  <div class="col-12">
                                    <div class="form-group">
                                      <textarea class="form-control" formControlName="no_incluye" rows="4" placeholder="No Incluye"></textarea>
                                    </div>                                          
                                  </div>                                  

                                  <div class="col-2">
                                    <button class="btn btn-primary btn-block" type="submit" >Agregar</button>
                                  </div>
                                </div>
          
                              </div>
                            </div>
                          </form>
                        </div> 

                        <!-- agregar actividad Editar -->
                        <div class="col-12" id="addActividadVerEdit" style="display: none;">
                          <form [formGroup]="nuevoActividadEdit" (ngSubmit)="newAddEdit()">
                            <div class="card">
                              <div class="card-body">
                                <div class="row">
                                  <div class="col-10">
                                    <div class="form-group">
                                      <input formControlName="nombre" placeholder="Nombre actividad" type="text" class="form-control"/>
                                    </div>                                          
                                  </div>
                                  <div class="col-12">
                                    <div class="form-group">
                                      <textarea class="form-control" formControlName="incluye" rows="4" placeholder="Incluye"></textarea>
                                    </div>                                          
                                  </div>

                                  <div class="col-12">
                                    <div class="form-group">
                                      <textarea class="form-control" formControlName="no_incluye" rows="4" placeholder="No Incluye"></textarea>
                                    </div>                                          
                                  </div>                                    
                                  <div class="col-2">
                                    <button class="btn btn-warning btn-block" type="submit" >Editar</button>
                                  </div>
                                </div>
          
                              </div>
                            </div>
                          </form>
                        </div>                    
    
                        <!-- listado Actividades -->
                        <div class="card" *ngFor="let item of selectActividadesEdit let i = index">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-10">
                                <h6 class="card-title">{{item.nombre | titlecase}}</h6>
                              </div>
                              
                              <div class="col-12" *ngIf="item.incluye">
                                <div class="card">
                                  <div class="card-body">
                                    {{item.incluye}}
                                  </div>
                                </div>                                
                              </div>  

                              <div class="col-12" *ngIf="item.no_incluye">
                                <div class="card">
                                  <div class="card-body">
                                    {{item.no_incluye}}
                                  </div>
                                </div>                                
                              </div>                                                            

                              <div class="col-2">
                                <button type="button" class="mr-1 btn btn-sm btn-primary" (click)="editAddEdit(i,item)"><i class="fa fa-edit"></i></button>
                                <button type="button" class="btn btn-sm btn-secondary" (click)="eliminarAddVer(i,item)"><i class="fa fa-trash"></i></button>    
                              </div>
                            </div>
                          </div>
                        </div>                    
                      </div>
                    </div>                
                    
                  </div>
    
                </div>

                <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Actualizar Proveedor</app-button>                       
            </div> 
          </div>

        </form>
      </div>
    </div>



  <!-- Modal Nueva  -->
  <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNueva" aria-labelledby="offcanvasNuevaLabel">
    <div class="offcanvas-header">
      <h5 id="offcanvasRightLabel">Nuevo Proveedor</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">

      <form [formGroup]="nuevoForm" (ngSubmit)="new()">
        <div class="col-12 mt-3" id="crearCliente">

          <div class="card">
            <div class="card-header">
              Detalle Proveedor
            </div>
            <div class="card-body">
              <div class="form-group">
                <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control"/>
              </div>
              <div class="form-group">
                <input formControlName="direccion" placeholder="Dirección" type="text" class="form-control"/>
              </div>  
              <div class="form-group">
                <input formControlName="ciudad" placeholder="Ciudad" type="text" class="form-control"/>
              </div>                                     
              <div class="form-group">
                <input formControlName="contacto" placeholder="Contacto" type="text" class="form-control"/>
              </div>              
            </div>
          </div>



            <div class="row">

              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    Actividades <button type="button" class="btn btn-sm btn-secondary" (click)="addActividad()"><i class="fa fa-plus"></i></button>
                  </div>
                  <div class="card-body">
                    <!-- agregar actividad -->
                    <div class="col-12" id="addActividadNew" style="display: none;">
                      <form [formGroup]="nuevoActividad" (ngSubmit)="newAdd()">
                        <div class="card">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-10">
                                <div class="form-group">
                                  <input formControlName="nombre" placeholder="Nombre actividad" type="text" class="form-control"/>
                                </div>                                          
                              </div>
                              <div class="col-12">
                                <div class="form-group">
                                  <textarea class="form-control" formControlName="incluye" rows="4" placeholder="Incluye"></textarea>
                                </div>                                          
                              </div>

                              <div class="col-12">
                                <div class="form-group">
                                  <textarea class="form-control" formControlName="no_incluye" rows="4" placeholder="No Incluye"></textarea>
                                </div>                                          
                              </div>                               
                              <div class="col-2">
                                <button class="btn btn-primary btn-block" type="submit" >Agregar</button>
                              </div>
                            </div>
      
                          </div>
                        </div>
                      </form>
                    </div>                    

                    <!-- listado Actividades -->
                    <div class="card" *ngFor="let item of selectActividades let i = index">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-10">
                            <h6 class="card-title">{{item.nombre | titlecase}}</h6>
                          </div>
                          <div class="col-12" *ngIf="item.incluye">
                            <div class="card">
                              <div class="card-body">
                                {{item.incluye}}
                              </div>
                            </div>                                
                          </div>  

                          <div class="col-12" *ngIf="item.no_incluye">
                            <div class="card">
                              <div class="card-body">
                                {{item.no_incluye}}
                              </div>
                            </div>                                
                          </div>  
                          <div class="col-2">
                            <button type="button" class="mr-1 btn btn-sm btn-primary" (click)="editAdd(i,item)"><i class="fa fa-edit"></i></button>
                            <button type="button" class="btn btn-sm btn-secondary" (click)="eliminarAdd(i,item)"><i class="fa fa-trash"></i></button>    
                          </div>
                        </div>
                      </div>
                    </div>                    
                  </div>
                </div>                
                
              </div>

            </div>
                    
            <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Crear Proveedor</app-button>                       
        </div> 
      </form>
    </div>
  </div>
