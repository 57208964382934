import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
declare var $: any;


@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.scss']
})
export class EmpresasComponent implements OnInit {

  public nuevaEmpresaForm: FormGroup;
  public editEmpresaForm: FormGroup;
  public editEmpresaUsuarioForm: FormGroup;
  public editUsuarioForm: FormGroup;
  
  public isAuthLoading = false;


  cargando: boolean =false;
  empresas: any = [];
  // usuarios_empresa: any = [];
  pagesNumber: any;
  offset= 3;
  buscar: any;
  page: any;
  public loadingImg = false;

  constructor( public router: Router,private toastr: ToastrService, private appService: AppService, private http: HttpClient) { }

  ngOnInit(): void {
    this.getListado();

    this.nuevaEmpresaForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      nit: new FormControl(null, Validators.required),
      direccion: new FormControl(null, Validators.required),
      telefono: new FormControl(null, Validators.required),
      encargado: new FormControl(null, Validators.required),
      sigla: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      color_principal: new FormControl(null, Validators.nullValidator),
      color_secundario: new FormControl(null, Validators.nullValidator),
      logo: new FormControl(null, Validators.nullValidator),
      logo_url: new FormControl(null, Validators.nullValidator),
    });
    
    this.editEmpresaForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
      nit: new FormControl(null, Validators.required),
      direccion: new FormControl(null, Validators.required),
      telefono: new FormControl(null, Validators.required),
      encargado: new FormControl(null, Validators.required),
      sigla: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      color_principal: new FormControl(null, Validators.nullValidator),
      color_secundario: new FormControl(null, Validators.nullValidator),
      cant_usuarios: new FormControl(null, Validators.required),      
      usuarios_empresa: new FormControl(null, Validators.nullValidator),
      logo: new FormControl(null, Validators.nullValidator),
      logo_url: new FormControl(null, Validators.nullValidator),
    }); 
    
    this.editEmpresaUsuarioForm = new FormGroup({
      empresa_id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
      perfil_id: new FormControl(null, Validators.required),
    });  
    
    this.editUsuarioForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      empresa_id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
      telefono: new FormControl(null, Validators.nullValidator),
      perfil_id: new FormControl(null, Validators.required),
    });      
  }
  

  async newEmpresa() {     
    if (this.nuevaEmpresaForm.valid) {
        this.isAuthLoading = true; 
          let params = {
              nuevo:true,
              campos: this.nuevaEmpresaForm.value,      
            };
            // console.log(params);
            this.appService.postEmpresa(params)
          .subscribe( (data) => {
              if (data["logger"] == true){
                  // console.log(data);
                  // this.router.navigate(['/']);
                  this.nuevaEmpresaForm.reset()
                  $('#nuevaEmpresa').modal('hide');
                  this.getListado();
              }else{
                  this.toastr.error(data["msg"]);
              }
          }); 
        this.isAuthLoading = false;
    } else {
        this.toastr.error('El formulario no es válido!');
    }
  }

  editEmpresaModal(item){    
    this.editEmpresaForm.get('id').setValue(item.id);
    this.editEmpresaForm.get('nombre').setValue(item.nombre);
    this.editEmpresaForm.get('nit').setValue(item.nit);
    this.editEmpresaForm.get('direccion').setValue(item.direccion);
    this.editEmpresaForm.get('telefono').setValue(item.telefono);
    this.editEmpresaForm.get('encargado').setValue(item.encargado);
    this.editEmpresaForm.get('sigla').setValue(item.sigla);
    this.editEmpresaForm.get('email').setValue(item.email);    
    this.editEmpresaForm.get('color_principal').setValue(item.color_principal);    
    this.editEmpresaForm.get('color_secundario').setValue(item.color_secundario);    
    this.editEmpresaForm.get('cant_usuarios').setValue(item.cant_usuarios);        
    this.editEmpresaForm.get('logo').setValue(item.logo);    
    this.editEmpresaForm.get('logo_url').setValue(item.logo_url);    
    this.editEmpresaForm.get('usuarios_empresa').setValue(item.usuarios_empresa);    
    $('#editEmpresa').modal('show');
  }

  editEmpresaUsuarioModal(item){
    console.log(item);    
    this.editUsuarioForm.get('id').setValue(item.id);
    this.editUsuarioForm.get('empresa_id').setValue(item.empresa_id);
    this.editUsuarioForm.get('nombre').setValue(item.nombre);
    this.editUsuarioForm.get('email').setValue(item.email);
    this.editUsuarioForm.get('telefono').setValue(item.telefono);
    this.editUsuarioForm.get('password').setValue(item.password);
    this.editUsuarioForm.get('perfil_id').setValue(item.perfil_id);
    $('#editEmpresaUsuario').modal('show');
  }

  async editEmpresa() {     
    if (this.editEmpresaForm.valid) {
        this.isAuthLoading = true; 
          let params = {
              editar:true,
              id: this.editEmpresaForm.value.id,
              campos: this.editEmpresaForm.value,
              estado:1,            
            };
            console.log(params);
            this.appService.postEmpresa(params)
          .subscribe( (data) => {
              if (data["logger"] == true){
                  // console.log(data);
                  this.toastr.success(data["msg"]);
                  this.editEmpresaForm.reset()
                  $('#editEmpresa').modal('hide');
                  this.getListado();
              }else{
                  this.toastr.error(data["msg"]);
              }
          }); 
        this.isAuthLoading = false;
    } else {
        this.toastr.error('El formulario no es válido!');
    }
  }  

  async editEmpresaUsuario(empresaId) {     
    this.editEmpresaUsuarioForm.get('empresa_id').setValue(empresaId);    
    
    if (this.editEmpresaUsuarioForm.valid) {
        this.isAuthLoading = true; 
          let params = {
              nuevo:true,
              empresa_id: this.editEmpresaUsuarioForm.value.empresa_id,
              nombre: this.editEmpresaUsuarioForm.value.nombre,
              email: this.editEmpresaUsuarioForm.value.email,
              password: this.editEmpresaUsuarioForm.value.password,
              perfil_id: this.editEmpresaUsuarioForm.value.perfil_id,
              estado:1,            
            };
            // console.log(params);
            this.appService.postUsuario(params)
          .subscribe( (data) => {
              if (data["logger"] == true){
                  // console.log(data);
                  // this.router.navigate(['/']);
                  this.editEmpresaForm.value.usuarios_empresa.push(data["obj"]);

                  this.toastr.success(data["msg"]);
                  this.editEmpresaUsuarioForm.reset()
                  $('#collapseAgregarUsuario').collapse('hide');
                  this.getListado();
              }else{
                  this.toastr.error(data["msg"]);
              }
          }); 
        this.isAuthLoading = false;
    } else {
        this.toastr.error('El formulario no es válido!');
    }
  } 

  async editUsuario(id) {     
    this.editUsuarioForm.get('id').setValue(id); 
    console.log(this.editUsuarioForm);
       
    
    if (this.editUsuarioForm.valid) {
        this.isAuthLoading = true; 
          let params = {
              editar:true,
              id: this.editUsuarioForm.value.id,
              empresa_id: this.editUsuarioForm.value.empresa_id,
              nombre: this.editUsuarioForm.value.nombre,
              email: this.editUsuarioForm.value.email,
              password: this.editUsuarioForm.value.password,
              telefono: this.editUsuarioForm.value.telefono,
              perfil_id: this.editUsuarioForm.value.perfil_id,
              estado:1,            
            };
            // console.log(params);
            this.appService.postUsuario(params)
          .subscribe( (data) => {
              if (data["logger"] == true){
                  this.toastr.success(data["msg"]);
                  this.editUsuarioForm.reset()
                  $('#editEmpresaUsuario').modal('hide');
                  $('#editEmpresa').modal('hide');
                  this.getListado();
              }else{
                  this.toastr.error(data["msg"]);
              }
          }); 
        this.isAuthLoading = false;
    } else {
        this.toastr.error('El formulario no es válido!');
    }
  }   

  getListado(page = null){
    this.cargando = true;
    if(!page){
      this.appService.getEmpresas(1).subscribe( (data) => {
        this.empresas = data['empresas'];        
        this.cargando = false;
        this.ForpagesNumber()
      });
    }else{
      var url = this.appService.getEmpresas(page).subscribe( (data) => {
        this.empresas = data['empresas'];
        this.cargando = false;
        this.ForpagesNumber()

      });
    }     
  }

  ForpagesNumber() {
    let from = this.empresas.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.empresas.total_page){
      to = this.empresas.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
  }

  changePage(page) {
    this.page = page; //para el filtro
    this.empresas.current = page;
    // this.filtro();
        // this.listadoComercios(page);        
  }
  
  
  uploadImg(files,componente) {
    if (files.length === 0)
      return;   
    this.loadingImg =true;
    var reader = new FileReader();
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      const imgURL = reader.result; 
    //   console.log("esta es la imagen"+imgURL);

      if(!imgURL) {
        console.log('error');
        return;
      } else {
        let params = {
          folder: 'logo',
          archivo: imgURL
        };
        // this.cargandoImg = true;
        this.appService.uploadImg(params)
        .subscribe( (data) => {
          if(data['logger'] == true){
            if(componente =='nuevo'){
              console.log('nuevo');
              
              this.nuevaEmpresaForm.get('logo').setValue(data['filename']);
              this.nuevaEmpresaForm.get('logo_url').setValue(data['fileUrl']);  
            }
            if(componente =='editar'){
              console.log('edit');

              this.editEmpresaForm.get('logo').setValue(data['filename']);
              this.editEmpresaForm.get('logo_url').setValue(data['fileUrl']);                
            } 
                                    
          }
          this.loadingImg =false;
        });
      }      
    }

  }  


}
