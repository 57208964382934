import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

declare var $: any;

@Component({
  selector: 'app-politicas',
  templateUrl: './politicas.component.html',
  styleUrls: ['./politicas.component.scss']
})
export class PoliticasComponent implements OnInit {
  public nuevoForm: FormGroup;
  public editForm: FormGroup;
  public isAuthLoading = false;

  public user: any = null;
  reporteExport:any;
  findInput: any;
  obj: any = [];
  pagesNumber: any;
  offset= 3;
  page= 1;  
  public cargando = false;


  constructor( public router: Router,private toastr: ToastrService, private appService: AppService, private http: HttpClient) { }

  ngOnInit(): void {
    this.user = this.appService.user;
    // console.log(this.user);
    this.getList();  
    
    this.nuevoForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      descripcion: new FormControl(null, Validators.required),
    });   
    
    this.editForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
      descripcion: new FormControl(null, Validators.nullValidator),
    });       

  }

  getList(){
    this.appService.getPoliticas(this.user.et+'-'+this.page).subscribe( (data) => {
    this.obj = data['obj']; 
    this.ForpagesNumber();
    this.cargando = false;
    });
  }


 

  changePage(page) {
    this.page = page; //para el filtro
    this.obj.current = page;
    this.getList();
  }
  
  ForpagesNumber() {
    let from = this.obj.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.obj.total_page){
      to = this.obj.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  async new() {     
    if (this.nuevoForm.valid) {
        this.isAuthLoading = true; 
          let params = {
              nuevo:true,
              et: this.user.et,
              nombre: this.nuevoForm.value.nombre,
              descripcion: this.nuevoForm.value.descripcion,
              estado: 1,
            };
            // console.log(params);
            this.appService.postPoliticas(params)
          .subscribe( (data) => {
            // console.log(data);
              if (data["logger"] == true){
                  this.nuevoForm.reset()
                  $('#nuevo').modal('hide');
                  this.toastr.success(data["msg"]);
                  this.getList();
              }else{
                  this.toastr.error(data["msg"]);
              }
          }); 
        this.isAuthLoading = false;
    } else {
        this.toastr.error('El formulario no es válido!');
    }
  }


  ver(item){
    // console.log(item);
    this.editForm.get('id').setValue(item.id);
    this.editForm.get('nombre').setValue(item.nombre);
    this.editForm.get('descripcion').setValue(item.descripcion);
    $('#ver').modal('show');
  }

  async edit(){
    if (this.editForm.valid) {
      this.isAuthLoading = true; 
        let params = {
            editar:true,
            id: this.editForm.value.id,
            nombre: this.editForm.value.nombre,
            descripcion: this.editForm.value.descripcion,
            // empresa_id: this.user.eid
          };
          // console.log(params);
          this.appService.postPoliticas(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
              this.toastr.success(data["msg"]);
              this.editForm.reset()
              $('#ver').modal('hide');
              this.getList();
            }else{
              this.toastr.error(data["msg"]);
            }
        }); 
      this.isAuthLoading = false;
  } else {
      this.toastr.error('El formulario no es válido!');
  }
  }

  async eliminar(item){
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar al Politica?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        // console.log('dentro true');
        
        let params = {
          eliminar : true,
          id: item.id,
          empresa_id: this.user.eid
        }
        this.appService.postPoliticas(params)
        .subscribe( (data) => {          
            if (data["logger"] == true){
                this.getList();
                this.toastr.success(data["msg"]);
            }else{
                this.toastr.error(data["msg"]);
            }
        });         

      }
    });  

  }

  async find() {     
    let params = {
        buscar:true,
        buscar_input: this.findInput,
        page: this.page,
        et: this.user.et,
      };
        this.appService.postPoliticas(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
              this.obj = data['obj']; 
            }else{
              this.toastr.error(data["msg"]);
            }
        });
  }


}