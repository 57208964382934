import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AppService } from '@services/app.service';
import moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-reserva-publica',
  templateUrl: './reserva-publica.component.html',
  styleUrls: ['./reserva-publica.component.scss']
})
export class ReservaPublicaComponent implements OnInit {
  id:any;
  tarjeta_reserva: any;
  empresa: any;
  noches: any;
  politicaNombre: any;
  politicaDescripcion: any;
  
  constructor(private route: ActivatedRoute,public router: Router,
    public appService: AppService) {
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.id = this.route.snapshot.paramMap.get('id');
        console.log(this.id);
        
        if(this.id){
          this.getReserva();      
        }else{
          // this.router.navigate(['/catalogo/']);
        } 

      });
   }

  ngOnInit(): void {
  }

  getReserva(){
    let params = {
      getPublico:true,
      id: this.id,
    };
    this.appService.postTarjetaReserva(params).subscribe( (data) => {      
      this.tarjeta_reserva = data['obj'];  
      this.calDias(this.tarjeta_reserva.fecha_llegada, this.tarjeta_reserva.fecha_out)               
      // this.empresa = this.tarjeta_reserva['empresa'];         
      console.log(this.tarjeta_reserva);   
    })
  }

  calDias(llegada,salida) {
    const fecha_inicio = moment(llegada);
    const fecha_termino = moment(salida);
    let dias = fecha_termino.diff(fecha_inicio, 'days');
    if(dias){
      this.noches = dias;
    }
  }

  politicaVer(item){
    console.log(item);
    this.politicaNombre = item.nombre;
    this.politicaDescripcion = item.descripcion;
    $('#politicaVer').modal('show');
  }

}
