<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-5">
            <h1>
              Politicas
              <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#nuevo"><i
                  class="fa fa-plus"></i></button>
            </h1>
          </div>
          <div class="col-7">
            <div class="input-group mb-0">
              <input type="text" class="form-control" [(ngModel)]="findInput" placeholder="Buscador">
              <button class="btn btn-secondary" type="button" id="button-addon2" (click)="find()"><i
                  class="fa fa-search"></i></button>
            </div>
          </div>
        </div>

      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item active">Politicas</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content m-2">
  <div class="card">
    <div class="card-body table-responsive p-0">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of obj.items">
            <td><span (click)="ver(item)">{{item.nombre | titlecase}}</span></td>
            <td>
              <div class="dropdown">
                <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"></button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><span class="dropdown-item" style="cursor: pointer;" (click)="ver(item)">Ver Politica</span></li>
                  <li><span class="dropdown-item text-danger" style="cursor: pointer;"
                      (click)="eliminar(item)">Eliminar</span></li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- paginador -->
  <div class="card-footer clearfix">
    <ul class="pagination pagination-sm m-0 float-right">
      <li class="page-item" *ngIf="obj.current > 1">
        <button type="button" class="page-link" (click)="changePage(obj.current - 1)">
          <span>Atras</span>
        </button>
      </li>

      <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': obj.current == page}">
        <button type="button" class="page-link" (click)="changePage(page)">
          {{ page }}
        </button>
      </li>

      <li class="page-item" *ngIf="obj.current < obj.total_page">
        <button type="button" class="page-link" (click)="changePage(obj.next)">
          <span>Siguiente</span>
        </button>
      </li>
    </ul>
    <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
      <p>Total registros encontrados: <b>{{obj.size}}</b></p>
    </div>
  </div>
  <!-- Fin paginador -->

</section>




<!-- Modal Ver  -->
<div class="modal fade" id="ver" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Cliente</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">

          <form [formGroup]="editForm" (ngSubmit)="edit()">
            <div class="row">

              <div class="col-12 mt-3">
                <div class="form-group mb-3">
                  <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control" />
                </div>

                <div class="form-group mb-3">
                  <textarea formControlName="descripcion" placeholder="Descripcion" type="text" class="form-control"
                    rows="5" style="height: 300px;"></textarea>
                </div>

                <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Actualizar Politica</app-button>
              </div>
            </div>

          </form>

        </div>

      </div>
    </div>
  </div>
</div>



<!-- Modal Nueva  -->
<div class="modal fade" id="nuevo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5>Nueva Politica</h5>
      </div>
      <div class="modal-body">
        <form [formGroup]="nuevoForm" (ngSubmit)="new()">
          <div class="col-12 mt-3">

            <div class="form-floating mb-3">
              <input formControlName="nombre" type="text" class="form-control" placeholder=">Nombre">
              <label>Nombre</label>
            </div>

            <div class="form-floating mb-3">
              <textarea formControlName="descripcion" class="form-control" placeholder="Descripcion"
                rows="5" style="height: 300px;"></textarea>
              <label for="floatingTextarea">Descripcion</label>
            </div>


            <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Crear Politica</app-button>
          </div>

        </form>
      </div>
    </div>


  </div>
</div>