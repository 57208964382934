import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;


@Component({
  selector: 'app-visas',
  templateUrl: './visas.component.html',
  styleUrls: ['./visas.component.scss']
})
export class VisasComponent implements OnInit {
  listFormato: any[];
  public user: any = null;

  public nuevoForm: FormGroup;
  public nuevoClienteForm: FormGroup;

  public editForm: FormGroup;
  public loadingImg = false;
  public isAuthLoading = false;
  public cargando = false;

  whatsappNumero: any;
  selectFormato: any;

  buscador: any;
  listClientes: any[];
  listInteresados: any[];
  listEnProceso: any[];
  listFinalizado: any[];
  clienteSeleccionado: any;
  VisaSeleccionada: any;

  adjunto_nombre: any;
  adjunto_archivo: any;
  adjunto_archivo_url: any;
  adjunto_archivo_extension: any;
  detalle_historial: any;


  constructor(public router: Router, private toastr: ToastrService, private appService: AppService, private http: HttpClient) { }

  ngOnInit(): void {
    this.user = this.appService.user;

    this.nuevoForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.nullValidator),
      requisitos: new FormControl(null, Validators.nullValidator),
      formulario: new FormControl(null, Validators.nullValidator),
      costo: new FormControl(null, Validators.nullValidator),
      costo_servicio: new FormControl(null, Validators.nullValidator),
      img: new FormControl(null, Validators.nullValidator),
      img_url: new FormControl(null, Validators.nullValidator),
    });

    this.editForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.nullValidator),
      requisitos: new FormControl(null, Validators.nullValidator),
      formulario: new FormControl(null, Validators.nullValidator),
      costo: new FormControl(null, Validators.nullValidator),
      costo_servicio: new FormControl(null, Validators.nullValidator),
      img: new FormControl(null, Validators.nullValidator),
      img_url: new FormControl(null, Validators.nullValidator),
    });

    this.getListFormato();
    this.getListClientes();
    this.getListVisas();
  }

  getListFormato() {
    this.appService.getVisaFormato().subscribe((data) => {
      this.listFormato = data['obj'];
      console.log(this.listFormato);
    });
  }

  nuevoFormato() {
  }

  ver(item) {
    console.log(item);
    this.editForm.get('id').setValue(item.id);
    this.editForm.get('nombre').setValue(item.nombre);
    this.editForm.get('requisitos').setValue(item.requisitos);
    this.editForm.get('costo').setValue(item.costo);
    this.editForm.get('costo_servicio').setValue(item.costo_servicio);
    this.editForm.get('img').setValue(item.img);
    this.editForm.get('img_url').setValue(item.img_url);
    console.log(this.editForm);
    $('#editarformato').modal('show');
  }

  editFormato() {
    if (this.editForm.valid) {
      this.isAuthLoading = true;
      let params = {
        editar: true,
        id: this.editForm.value.id,
        nombre: this.editForm.value.nombre,
        requisitos: this.editForm.value.requisitos,
        costo: this.editForm.value.costo,
        costo_servicio: this.editForm.value.costo_servicio,
        img: this.editForm.value.img,
      };
      // console.log(params);
      this.appService.postVisaFormato(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.toastr.success(data["msg"]);
            this.editForm.reset()
            $('#editarformato').modal('hide');
            this.getListFormato();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  selectFormatoSend(item) {
    console.log(item);
    this.selectFormato = item;
  }

  selectCliente(item) {
    this.buscador = null;
    this.clienteSeleccionado = item;
    console.log(item);
  }

  createCliente(buscador) {
    this.buscador = null;
    $('#crearCliente').show();
  }

  async newCliente() {
    if (this.nuevoClienteForm.valid) {
      this.isAuthLoading = true;
      let params = {
        nuevo: true,
        nombre: this.nuevoClienteForm.value.nombre,
        cc: this.nuevoClienteForm.value.cc,
        celular: this.nuevoClienteForm.value.celular,
        direccion: this.nuevoClienteForm.value.direccion,
        email: this.nuevoClienteForm.value.email,
        et: this.user.et,
      };
      this.appService.postClientes(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.nuevoForm.get('id').setValue(data["data"].id);
            this.nuevoForm.get('nombre').setValue(data["data"].nombre);
            this.nuevoForm.get('cc').setValue(data["data"].cc);
            this.nuevoForm.get('email').setValue(data["data"].email);
            this.nuevoForm.get('direccion').setValue(data["data"].direccion);
            this.nuevoForm.get('celular').setValue(data["data"].celular);
            // this.router.navigate(['/']);
            this.nuevoClienteForm.reset()
            $('#crearCliente').hide();
            this.getListClientes();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  getListClientes() {
    this.appService.getClientes(this.user.et).subscribe((data) => {
      this.listClientes = data['clientes'].items;
      this.cargando = false;
    });
  }

  crearSolicitud() {
    let params = {
      nuevoVisa: true,
      cliente_id: this.clienteSeleccionado.id,
      visa_id: this.selectFormato.id,
      valor_servicio: this.selectFormato.costo_servicio,
      ut: this.user.token,
      et: this.user.et,
    };
    this.appService.postVisaFormato(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.clienteSeleccionado = null;
          this.selectFormato = null;
          $('#offcanvasNuevaSolicitud').offcanvas('hide');
          this.getListVisas();
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  preview(files, componente) {
    if (files.length === 0)
      return;
    this.loadingImg = true;
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      const imgURL = reader.result;
      //   console.log("esta es la imagen"+imgURL);

      if (!imgURL) {
        console.log('error');
        return;
      } else {
        let params = {
          folder: 'visa-formato',
          archivo: imgURL
        };
        // this.cargandoImg = true;
        this.appService.uploadImg(params)
          .subscribe((data) => {
            if (data['logger'] == true) {
              if (componente == 'nuevo') {
                this.nuevoForm.get('img').setValue(data['filename']);
                this.nuevoForm.get('img_url').setValue(data['fileUrl']);
              }
              if (componente == 'editar') {
                this.nuevoForm.get('img').setValue(data['filename']);
                this.nuevoForm.get('img_url').setValue(data['fileUrl']);
              }
              this.getListFormato();

            }
            this.loadingImg = false;
          });
      }
    }

  }

  getListVisas() {
    let params = {
      get: true,
      estado: 1,
      ut: this.user.token,
      et: this.user.et,
    };
    this.appService.postVisaHistorial(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          console.log(data);
          this.listInteresados = data['obj'].items;
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  getListVisasEnproceso() {
    let params = {
      getEnproceso: true,
      estado: 1,
      ut: this.user.token,
      et: this.user.et,
    };
    this.appService.postVisaHistorial(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          console.log(data);
          this.listEnProceso = data['obj'].items;
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }
  getListVisasFinalizado() {
    let params = {
      getFinalizado: true,
      estado: 1,
      ut: this.user.token,
      et: this.user.et,
    };
    this.appService.postVisaHistorial(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          console.log(data);
          this.listFinalizado = data['obj'].items;
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  verVisa(item) {
    this.VisaSeleccionada = item;
    this.adjunto_nombre = '';
    this.detalle_historial = '';
    console.log(this.VisaSeleccionada);
    $('#vervisamodal').modal('show');
  }

  cambiarEstado(estado: number, id: number) {
    let params = {
      editEstado: true,
      id : id,
      estado: estado,
      ut: this.user.token,
      et: this.user.et,
    };
    this.appService.postVisaHistorial(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          // console.log(data);
          $('#vervisamodal').modal('hide');
          this.getListVisas();
          this.getListVisasEnproceso();
          this.getListVisasFinalizado();
        } else {
          this.toastr.error(data["msg"]);
        }
      });    
  }

  uploadArchivo(files) {
    if (files.length === 0)
      return;
    this.loadingImg = true;
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      const imgURL = reader.result;

      if (!imgURL) {
        console.log('error');
        return;
      } else {
        let params = {
          folder: 'adjuntos',
          archivo: imgURL
        };
        this.appService.uploadImg(params)
          .subscribe((data) => {
            // console.log(data);

            if (data['logger'] == true) {
              this.adjunto_archivo = data['filename'];
              this.adjunto_archivo_url = data['fileUrl'];
              this.adjunto_archivo_extension = data['extension'];
              this.cargarArchivo();
            }
            this.loadingImg = false;
          });
      }
    }
  }

  cargarArchivo() {
    let params = {
      nuevo: true,
      et: this.user.et,
      // id: this.tarjeta_reserva_id,
      nombre: this.adjunto_nombre,
      adjunto: this.adjunto_archivo,
      extension: this.adjunto_archivo_extension
    };
    this.appService.postTarjetaReservaAdjuntos(params).subscribe((data) => {
      // this.listAdjuntos = data['obj'];
      // this.getListAdjuntos(this.tarjeta_reserva_id);
      this.adjunto_archivo = '';
      this.adjunto_nombre = '';
      this.adjunto_archivo_url = '';
      this.adjunto_archivo_extension = '';
      this.cargando = false;
    });
  }

}
